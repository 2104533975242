const checkNull = function (value) {
  if (value === undefined) return 0;
  else if (value === null) return 0;
  else if (value === "") return 0;
  else if (value === NaN) return 0;
  else return value;
};
const checkNullFloat = function (value) {
  if (value === undefined) return 0;
  else if (value === null) return 0;
  else if (value === "") return 0;
  else if (value.toString() === "NaN") return 0;
  else return parseFloat(value);
};

const checkString = function (value) {
  if (value === undefined) return "";
  else if (value === null) return "";
  else if (value === NaN) return "";
  else return value.toString();
};

const checkNullString = function (value) {
  if (value === undefined) return true;
  else if (value === null) return true;
  else if (value === "") return true;
  else return false;
};

const checkNullNumber = function (value) {
  if (value === undefined) return true;
  else if (value === null) return true;
  else if (value === "999") return true;
  else if (value === 999) return true;
  else return false;
};

export {
  checkNull,
  checkString,
  checkNullFloat,
  checkNullString,
  checkNullNumber,
};
