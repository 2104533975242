import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import logo from "../assets/images/logo.png";
import { Button } from "react-bootstrap";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([""]);
  const [rememberMe, setRememberMe] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    removeCookie("auth-token");
    if (
      localStorage.getItem("rememberMe") !== undefined &&
      localStorage.getItem("rememberMe") !== null
    ) {
      const userName = JSON.parse(localStorage.getItem("rememberMe")).userName;
      setUserName(userName);
      setRememberMe(true);
    }
  }, []);
  const setUserNameVal = (val) => {
    setUserName(val);
  };

  const setPasswordVal = (val) => {
    setPassword(val);
  };
  function downHandler(e) {
    if (e.key === "Enter") {
      loginUser();
    }
  }
  const loginUser = async () => {
    //setError("aaaa");
    if (!userName) {
      setError("Хэрэглэгчийн нэр хоосон байна!");
      return;
    }
    if (!password) {
      setError("Нууц үг хоосон байна!");
      return;
    }
    try {
      if (rememberMe) {
        localStorage.removeItem("rememberMe");
        localStorage.setItem(
          "rememberMe",
          JSON.stringify({
            userName: userName,
          })
        );
      } else {
        localStorage.removeItem("rememberMe");
      }
      const result = (
        await axios.post("/loginUser", {
          userCode: userName,
          userPassword: password,
        })
      ).data;

      setCookie("auth-token", result.token);
      if (
        result.user[0].ROLE_LEVEL_ID === 1 ||
        result.user[0].ROLE_LEVEL_ID === 2 ||
        result.user[0].ROLE_LEVEL_ID === 99
      )
        navigate("/dashboard");
      else navigate("/elder");
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const ErrorMessage = ({ errorCondition }) => {
    if (errorCondition) {
      return (
        <div role="alert" class="fade alert alert-danger show">
          {error}
        </div>
      );
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-start py-5 px-4 px-sm-5">
                  <div className="brand-logo" style={{ textAlign: "center" }}>
                    <img src={logo} alt="logo" />
                  </div>
                  {/* <h4>Hello! let's get started</h4>
                    <h6 className="fw-light">Sign in to continue.</h6> */}
                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Нэвтрэх нэр"
                        value={userName}
                        onChange={(event) => setUserNameVal(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Нууц үг"
                        value={password}
                        onChange={(event) => setPasswordVal(event.target.value)}
                        onKeyDown={downHandler}
                      />
                    </div>

                    <div className="w-80 ml-5 p-1 lg:text-left xl:text-left xl:pl-10 2xl:text-center 2xl:pl-0">
                      {/* appearance-none bg-white rounded-sm outline-none */}
                      <input
                        className={"w-4 h-4 mt-1"}
                        type="checkbox"
                        onChange={() => setRememberMe(!rememberMe)}
                        checked={rememberMe}
                        id="remember_me"
                      />
                      <label
                        className="text-gray-400 ps-1 text-sm"
                        htmlFor="remember_me"
                      >
                        Нэвтрэх нэр сануулах
                      </label>
                    </div>
                    <div className="my-3 text-center">
                      <Button
                        variant="outline-primary"
                        onClick={() => loginUser()}
                        size="sm"
                        //className="me-2 d-flex justify-content-center align-items-center px-2"
                      >
                        НЭВТРЭХ
                      </Button>
                    </div>

                    {/* <UploadImages /> */}
                    <ErrorMessage errorCondition={error !== ""} />
                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div>
                      <a href="#" className="auth-link text-black">
                          Forgot password?
                        </a>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
