import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card, Form, Modal, Tab, Tabs } from "react-bootstrap";

import Dropdown from "../components/custom/Dropdown";
import TableBasic from "../components/custom/TableBasic";
import { FiEye, FiPlusSquare, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import DateFormat from "../helper/DateFormat";
import { ExportToExcel } from "../helper/ExportToExcel";
import IsAuth from "../helper/IsAuth";
import UserContext from "../helper/UserContext";
import Loader from "../components/custom/Loader";
import Pagination from "../components/custom/Pagination";
import TableDefault from "../components/custom/TableDefault";

export default function Elder(props) {
  const userData = useContext(UserContext);
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [filterList, setFilterList] = useState({
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
    GENDER: 999,
    SEARCH: "",
  });
  const [dataList, setDataList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const [dataMovement, setDataMovement] = useState({
    TYPE_ID: 0,
    ELDER_ID: 0,
    MOVEMENT_ID: 0,
    RECEIVE_OFFICE_ID: 999,
    RECEIVE_SUB_OFFICE_ID: 999,
    MOVEMENT_DESC: "",
    CREATED_BY: 0,
  });

  const [showRemove, setShowRemove] = useState(false);
  const handleCloseRemove = () => setShowRemove(false);
  const handleShowRemove = () => setShowRemove(true);
  const [showRemoveDead, setShowRemoveDead] = useState(false);
  const handleCloseRemoveDead = () => setShowRemoveDead(false);
  const handleShowRemoveDead = () => setShowRemoveDead(true);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const filterData = () => {
    setFilteredList(dataList);

    if (filterList.OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.ELDER_OFFICE_ID === filterList.OFFICE_ID)
      );
    }
    if (filterList.SUB_OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.ELDER_SUB_OFFICE_ID === filterList.SUB_OFFICE_ID)
      );
    }
    if (filterList.GENDER !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.ELDER_GENDER === filterList.GENDER)
      );
    }

    if (filterList.SEARCH !== "") {
      setFilteredList((a) => a.filter((b) => b.SEARCH === filterList.SEARCH));
    }
  };

  const columns = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Харах",
      accessor: (row, index) => {
        return (
          <div className="text-center">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => navigate("/elder/" + row.ID)}
            >
              <FiEye />
            </Button>
          </div>
        );
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Регистрийн дугаар",
      accessor: "ELDER_REGISTER_NO",
    },
    {
      Header: "Эцэг эхийн нэр",
      accessor: "ELDER_LASTNAME",
    },
    {
      Header: "Өөрийн нэр",
      accessor: "ELDER_FIRSTNAME",
    },
    {
      Header: "Нас",
      accessor: "ELDER_AGE",
    },
    {
      Header: "Хүйс",
      accessor: (row, index) => {
        return row.ELDER_GENDER === 1 ? "Эрэгтэй" : "Эмэгтэй";
      },
    },
    {
      Header: "Утасны дугаар",
      accessor: "ELDER_PHONE",
    },
    {
      Header: "Оршин суугаа хаяг",
      accessor: "ELDER_ADDRESS",
    },
    {
      Header: "Аймаг, Дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, Хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Үйлдэл", // Header: "Аудитын үе шат",
      accessor: (row) => (
        <div className="text-center">
          {IsAuth(userData, 203) && (
            <Button
              variant="outline-secondary"
              onClick={() => elderRemove(row.ID)}
              size="sm"
            >
              <FiTrash />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const columns1and2 = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Харах",
      accessor: (row, index) => {
        return (
          <div className="text-center">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => navigate("/elder/" + row.ELDER_ID)}
            >
              <FiEye />
            </Button>
          </div>
        );
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Регистрийн дугаар",
      accessor: "ELDER_REGISTER_NO",
    },
    {
      Header: "Эцэг эхийн нэр",
      accessor: "ELDER_LASTNAME",
    },
    {
      Header: "Өөрийн нэр",
      accessor: "ELDER_FIRSTNAME",
    },
    {
      Header: "Нас",
      accessor: "ELDER_AGE",
    },
    {
      Header: "Хүйс",
      accessor: (row, index) => {
        return row.ELDER_GENDER === 1 ? "Эрэгтэй" : "Эмэгтэй";
      },
    },
    {
      Header: "Утасны дугаар",
      accessor: "ELDER_PHONE",
    },
    {
      Header: "Шилжүүлсэн Аймаг, Дүүрэг",
      accessor: "SEND_OFFICE_NAME",
    },
    {
      Header: "Шилжүүлсэн сум, хороо",
      accessor: "SEND_SUB_OFFICE_NAME",
    },
    {
      Header: "Хүлээж авах Аймаг, Дүүрэг",
      accessor: "RECEIVE_OFFICE_NAME",
    },
    {
      Header: "Хүлээж авах сум, хороо",
      accessor: "RECEIVE_SUB_OFFICE_NAME",
    },
  ];
  const columns3 = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Харах",
      accessor: (row, index) => {
        return (
          <div className="text-center">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => navigate("/elder/" + row.ID)}
            >
              <FiEye />
            </Button>
          </div>
        );
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Регистрийн дугаар",
      accessor: "ELDER_REGISTER_NO",
    },
    {
      Header: "Эцэг эхийн нэр",
      accessor: "ELDER_LASTNAME",
    },
    {
      Header: "Өөрийн нэр",
      accessor: "ELDER_FIRSTNAME",
    },
    {
      Header: "Нас",
      accessor: "ELDER_AGE",
    },
    {
      Header: "Хүйс",
      accessor: (row, index) => {
        return row.ELDER_GENDER === 1 ? "Эрэгтэй" : "Эмэгтэй";
      },
    },
    {
      Header: "Нас барсан огноо",
      accessor: (row, index) => {
        return DateFormat(row.MOVEMENT_DATE);
      },
    },
    {
      Header: "Аймаг, Дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, Хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Үйлдэл",
      accessor: (row) => (
        <div className="text-center">
          {IsAuth(userData, 203) && (
            <Button
              variant="outline-secondary"
              onClick={() => elderRemoveDead(row.ID)}
              size="sm"
            >
              <FiTrash />
            </Button>
          )}
        </div>
      ),
    },
  ];
  const excelHeader = [
    "№",
    "Регистрийн дугаар",
    "Эцэг эхийн нэр",
    "Өөрийн нэр",
    "Нас",
    "Хүйс",
    "Утасны дугаар",
    "Оршин суугаа хаяг",
    "Аймаг, Дүүрэг",
    "Сум, Хороо",
  ];
  const rows = filteredList.map((row, i) => ({
    index: i + 1,
    ELDER_REGISTER_NO: row.ELDER_REGISTER_NO,
    ELDER_LASTNAME: row.ELDER_LASTNAME,
    ELDER_FIRSTNAME: row.ELDER_FIRSTNAME,
    ELDER_AGE: row.ELDER_AGE,
    ELDER_GENDER: row.ELDER_GENDER === 1 ? "Эрэгтэй" : "Эмэгтэй",
    ELDER_PHONE: row.ELDER_PHONE,
    ELDER_ADDRESS: row.ELDER_ADDRESS,
    OFFICE_NAME: row.OFFICE_NAME,
    SUB_OFFICE_NAME: row.SUB_OFFICE_NAME,
  }));
  const [dropDown, setDropDown] = useState({
    office: [],
    subOffice: [],
  });

  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize, filterList]);
  const getLibrary = async () => {
    try {
      const result1 = (await axios.get("/refOffice")).data;
      const result2 = (await axios.get("/refSubOffice")).data;

      setDropDown({
        office: result1,
        subOffice: result2,
      });
    } catch (error) {}
  };

  async function fetchData({ pageIndex, pageSize }) {
    try {
      setLoading(true);
      const result = (
        await axios.post("/regElder", {
          USER_OFFICE_ID: filterList.OFFICE_ID,
          USER_SUB_OFFICE_ID: filterList.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_OFFICE: filterList.OFFICE_ID,
          FILTER_SUB_OFFICE: filterList.SUB_OFFICE_ID,
          FILTER_GENDER: filterList.GENDER,
          pageIndex: pageIndex,
          pageSize: pageSize,
          SEARCH: filterList.SEARCH,
        })
      ).data;

      setDataList(result);
      setFilteredList(result);

      const resultELderCNT = (
        await axios.post("/regElderCnt", {
          USER_OFFICE_ID: filterList.OFFICE_ID,
          USER_SUB_OFFICE_ID: filterList.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_OFFICE: filterList.OFFICE_ID,
          FILTER_SUB_OFFICE: filterList.SUB_OFFICE_ID,
          FILTER_GENDER: filterList.GENDER,
          SEARCH: filterList.SEARCH,
        })
      ).data;

      setPageCount(resultELderCNT.ELDER_CNT);
      const result1 = (
        await axios.post("/movementElder", {
          type: 1,
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setData1(result1);
      const result2 = (
        await axios.post("/movementElder", {
          type: 2,
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setData2(result2);
      const result3 = (
        await axios.post("/diedElder", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setData3(result3);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function elderRemove(elderId) {
    try {
      setDataMovement({
        TYPE_ID: 3,
        ELDER_ID: elderId,
        MOVEMENT_ID: 0,
        RECEIVE_OFFICE_ID: 999,
        RECEIVE_SUB_OFFICE_ID: 999,
        MOVEMENT_DESC: "",
        CREATED_BY: 0,
      });

      handleShowRemove();
    } catch (error) {}
  }
  async function elderRemoveDead(elderId) {
    try {
      setDataMovement({
        TYPE_ID: 3,
        ELDER_ID: elderId,
        MOVEMENT_ID: 0,
        RECEIVE_OFFICE_ID: 999,
        RECEIVE_SUB_OFFICE_ID: 999,
        MOVEMENT_DESC: "",
        CREATED_BY: 0,
      });

      handleShowRemoveDead();
    } catch (error) {}
  }
  const saveMovement = async () => {
    try {
      setLoading(true);
      let result = {};

      result = await axios.post("/regElderChange", dataMovement);

      alert(result.data.message);
      handleCloseRemove();
      handleCloseRemoveDead();
      setLoading(false);
      fetchData();
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase">Ахмадын бүртгэл</Card.Title>
            <Tabs defaultActiveKey="main" id="justify-tab-example">
              <Tab eventKey="main" title="Үндсэн">
                <div className="d-flex ">
                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 3 ||
                      userData.user.ROLE_LEVEL_ID === 4 ||
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterList.OFFICE_ID}
                      onChange={(e) => {
                        let value = filterList;
                        value.OFFICE_ID = parseInt(e.target.value);

                        if (
                          dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                          )
                        ) {
                          value.SUB_OFFICE_ID = 999;
                        }
                        setFilterList({ ...value });
                        filterData();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 1 ||
                        userData.user.ROLE_LEVEL_ID === 99
                          ? dropDown.office
                          : userData.user.ROLE_LEVEL_ID === 2
                          ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                          : dropDown.office.filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.OFFICE_ID)
                            )
                      }
                    />
                  </div>

                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterList.SUB_OFFICE_ID}
                      onChange={(e) => {
                        let value = filterList;
                        value.SUB_OFFICE_ID = parseInt(e.target.value);
                        setFilterList({ ...value });
                        filterData();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 5
                          ? dropDown.subOffice
                              ?.filter(
                                (a) =>
                                  parseInt(a.OFFICE_ID) ===
                                  parseInt(filterList.OFFICE_ID)
                              )
                              .filter(
                                (a) =>
                                  parseInt(a.ID) ===
                                  parseInt(userData.user.SUB_OFFICE_ID)
                              )
                          : dropDown.subOffice?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(filterList.OFFICE_ID)
                            )
                      }
                    />
                  </div>
                  <div className="me-2">
                    <Form.Select
                      value={filterList.GENDER}
                      onChange={(e) => {
                        let value = filterList;
                        value.GENDER = parseInt(e.target.value);
                        setFilterList({ ...value });
                        filterData();
                      }}
                      size="sm"
                    >
                      <option value={999}>Сонгоно уу</option>
                      <option value={1}>Эрэгтэй</option>
                      <option value={2}>Эмэгтэй</option>
                    </Form.Select>
                  </div>
                  <div className="me-2">
                    <Form.Control
                      size="sm"
                      value={filterList.SEARCH}
                      placeholder="Хайх"
                      onChange={(e) => {
                        let value = filterList;
                        value.SEARCH = e.target.value;
                        setFilterList({ ...value });
                        filterData();
                      }}
                    />
                  </div>
                  {IsAuth(userData, 201) && (
                    <Button
                      variant="light"
                      onClick={() => navigate("/elder/0")}
                      size="sm"
                      className="me-2 d-flex justify-content-center align-items-center px-2"
                    >
                      <FiPlusSquare className="me-2" />
                      Нэмэх
                    </Button>
                  )}
                  <ExportToExcel
                    colData={rows}
                    fileName={
                      "Ахмадын бүртгэл " + DateFormat(Date.now(), "time")
                    }
                    colHeader={excelHeader}
                  />
                  <div style={{ marginLeft: "auto" }}>
                    <input
                      placeholder="Хайх"
                      className="form-control form-control-sm text-secondary bg-transparent text-center border-0"
                      value={`Нийт: ${pageCount} бичлэг`}
                      readOnly
                      disabled
                    ></input>
                  </div>
                </div>
                <div className="table-responsive p-2">
                  <TableBasic
                    columns={columns}
                    data={filteredList}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                  />
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      className="form-control"
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      style={{ width: "120px", height: "38px" }}
                    >
                      {[10, 30, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} -р харах
                        </option>
                      ))}
                    </select>
                    <Pagination
                      totalRows={pageCount}
                      pageChangeHandler={setPageIndex}
                      rowsPerPage={pageSize}
                      currentPage={pageIndex}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="movement" title={`Шилжсэн ${data1.length}`}>
                <div className="table-responsive p-2">
                  <TableDefault columns={columns1and2} data={data1} />
                </div>
              </Tab>
              <Tab eventKey="receive" title={`Хүлээж авах ${data2.length}`}>
                <div className="table-responsive p-2">
                  <TableDefault columns={columns1and2} data={data2} />
                </div>
              </Tab>
              <Tab eventKey="died" title="Нас барсан">
                <div className="table-responsive p-2">
                  <TableDefault columns={columns3} data={data3} />
                </div>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
        <Modal show={showRemove} onHide={handleCloseRemove}>
          <Modal.Header closeButton>
            <Modal.Title>Бүртгэлээс хасах</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Check
                className="form-check form-check-flat form-check-primary"
                onChange={(e) => {
                  let value = dataMovement;
                  value.TYPE_ID = e.target.checked ? 4 : 3;
                  setDataMovement({ ...value });
                }}
                id="Нас барсан"
                label="Нас барсан"
              />
              <Form.Group className="mb-3">
                <Form.Label>Тайлбар</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataMovement.MOVEMENT_DESC}
                  onChange={(e) => {
                    let value = dataMovement;
                    value.MOVEMENT_DESC = e.target.value;
                    setDataMovement({ ...value });
                  }}
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => saveMovement()}
                  size="sm"
                  className="me-2"
                >
                  Устгах
                </Button>
                <Button
                  variant="light"
                  type="button"
                  onClick={handleCloseRemove}
                  size="sm"
                >
                  Хаах
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal show={showRemoveDead} onHide={handleCloseRemoveDead}>
          <Modal.Header closeButton>
            <Modal.Title>Нас барсан бүртгэлээс хасах</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Тайлбар</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataMovement.MOVEMENT_DESC}
                  onChange={(e) => {
                    let value = dataMovement;
                    value.MOVEMENT_DESC = e.target.value;
                    setDataMovement({ ...value });
                  }}
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => saveMovement()}
                  size="sm"
                  className="me-2"
                >
                  Устгах
                </Button>
                <Button
                  variant="light"
                  type="button"
                  onClick={handleCloseRemoveDead}
                  size="sm"
                >
                  Хаах
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      {loading && <Loader />}
    </div>
  );
}
