// src/components/pagination.table.js
import React from "react";

import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
} from "react-table";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Хайх:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} бичлэг...`}
        style={{
          border: "1px solid #e7eaed",
          borderRadius: "0.25rem",
        }}
      />
    </span>
  );
}

function Table({ columns, data, pageCount1, pageSize1, pageIndex1 }) {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageIndex1,
        pageSize: pageSize1,
        pageCount: pageCount1,
      },
      manualPagination: true,
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // Render the UI for your table
  return (
    <div>
      {pageCount1 === undefined ? (
        <div className="mb-2" style={{ float: "right" }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      ) : null}

      {/* <pre>
        <code>
          {JSON.stringify(
            {
              // pageIndex,
              // pageSize,
              // pageCount,
              canNextPage,
              canPreviousPage,
              pageIndex1,
              pageSize1,
              pageCount1,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <table
        className="table table-striped elder-table-rounded"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-primary"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`text-center`}
                  style={{ fontWeight: 700 }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="font-weight-normal"
                      style={{ fontWeight: 400 }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      {pageCount1 === undefined ? (
        <div className="d-flex justify-content-between mt-2">
          <select
            className="form-control"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[10, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} -р харах
              </option>
            ))}
          </select>
          <ul className="pagination">
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <span className="page-link">{"<<"}</span>
            </li>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <span className="page-link">{"<"}</span>
            </li>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <span className="page-link">{">"}</span>
            </li>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <span className="page-link">{">>"}</span>
            </li>
            <li className="page-item" style={{ cursor: "pointer" }}>
              <span className="page-link">
                Хуудас{" "}
                <strong>
                  {pageIndex + 1} / {pageOptions.length}
                </strong>{" "}
              </span>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
}

function TableBasic(props) {
  const columns = React.useMemo(() => props.columns, []);

  const data = props.data;
  const pageCount = props.pageCount;
  const pageIndex = props.pageIndex;
  const pageSize = props.pageSize;

  return (
    <Table
      columns={columns}
      data={data}
      pageCount1={pageCount}
      pageIndex1={pageIndex}
      pageSize1={pageSize}
    />
  );
}

export default TableBasic;
