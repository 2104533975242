import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-wrapper">
        <footer className="footer">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-center text-sm-left d-block d-sm-inline-block">
              Copyright © 2022.
            </span>
          </div>
        </footer>
      </div>
    );
  }
}
