import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import { AiOutlineFileExcel } from "react-icons/ai";
import { useEffect } from "react";
import DateFormat from "../helper/DateFormat";
import moment from "moment";

export const ExportToExcel = ({ colData, fileName, colHeader }) => {
  //console.log(colData, filteredData);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const now = moment();
  const exportToXLSX = (rows, fileName) => {
    const ws = XLSX.utils.json_to_sheet(rows);
    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [colHeader], {
      origin: "A1",
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="success"
      size="sm"
      className="d-flex justify-content-center align-items-center text-white px-2"
      onClick={(e) => {
        if (DateFormat(Date.now()) < DateFormat(Date.parse("31 Dec 2024"))) {
          alert("2024-12-31 хүртэл уг үйлдлийг түр хаасан байна.");
        } else {
          exportToXLSX(colData, fileName);
        }
      }}
    >
      <AiOutlineFileExcel className="me-2" />
      Excel
    </Button>
  );
};
