import React, { useContext } from "react";
import user from "../assets/images/user.jpg";
import UserContext from "../helper/UserContext";

export default function Profile() {
  const userData = useContext(UserContext);
  console.log(userData, "userDatauserData");
  return (
    <li className="nav-item nav-profile">
      <div className="nav-link d-flex">
        <div className="profile-image">
          <img src={user} alt="image" />
        </div>
        <div className="profile-name">
          <p className="name">
            <span>{userData.user.USER_LASTNAME} </span>
            <br />
            <span className="text-uppercase">
              {userData.user.USER_FIRSTNAME}
            </span>
          </p>
          <p className="designation text-wrap">
            {userData.user.OFFICE_NAME}, {userData.user.SUB_OFFICE_NAME}
            <br />
            {userData.user.ROLE_NAME}
            <br />
            {userData.user.USER_PHONE}
          </p>
        </div>
      </div>
    </li>
  );
}
