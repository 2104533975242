import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

export default function Survey(props) {
  return (
    <div className="col-12 grid-margin">
      <Card>
        <Card.Body>
          <Card.Title className="text-uppercase">Судалгаа</Card.Title>
          <div className="list-group">
            <a href="#" className="list-group-item list-group-item-action">
              Маягт 1
            </a>
            <a href="#" className="list-group-item list-group-item-action">
              Маягт 1-1
            </a>
            <a href="#" className="list-group-item list-group-item-action">
              Маягт 1-2
            </a>
            <a href="#" className="list-group-item list-group-item-action">
              Маягт 1-3
            </a>
            <a href="#" className="list-group-item list-group-item-action ">
              Маягт 1-4
            </a>
            <a href="#" className="list-group-item list-group-item-action ">
              Маягт 1-5
            </a>
            <a href="#" className="list-group-item list-group-item-action ">
              Маягт 2
            </a>
            <a href="#" className="list-group-item list-group-item-action ">
              Маягт 3-1
            </a>
            <a href="#" className="list-group-item list-group-item-action ">
              Маягт 3-2
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
