import axios from "axios";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
export function useToken() {
  const [cookies] = useCookies([""]);

  useEffect(() => {
    axios.interceptors.request.use((request) => {
      request.headers["authorization"] = `Bearer ${cookies["auth-token"]}`;
      return request;
    });
  });
}
