import React from "react";
import { Form } from "react-bootstrap";

export default function Dropdown(props) {
  return (
    <>
      <Form.Select
        value={props.value}
        onChange={(e) => {
          props.onChange(e);
        }}
        size="sm"
      >
        <option key={"999"} value={999}>
          Сонгоно уу
        </option>
        {props?.options?.map((value, index) => (
          <option key={value.ID} value={value.ID}>
            {value.NAME}
          </option>
        ))}
      </Form.Select>
    </>
  );
}
