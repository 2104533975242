import React from "react";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";

export default function ChartPie(props) {
  const data = {
    labels: props.pieData.label,
    datasets: [
      {
        label: "Тоо",
        data: props.pieData.value,
        borderWidth: 1,
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: true,
    },
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <h4 className="card-title" style={{ textTransform: "none" }}>
            {props.pieData.title}
          </h4>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ maxHeight: "350px", height: "300px" }}
        >
          <Pie data={data} />
        </div>
      </div>
    </div>
  );
}
