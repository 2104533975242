import axios from "axios";
import {
  FiPlusSquare,
  FiSave,
  FiLogOut,
  FiEdit,
  FiTrash,
  FiDownload,
} from "react-icons/fi";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Dropdown from "../components/custom/Dropdown";
import ConfirmModal from "../components/custom/RemoveConfirm";
import DateFormat from "../helper/DateFormat";
import { useContext } from "react";
import UserContext from "../helper/UserContext";
import { ExportToExcel } from "../helper/ExportToExcel";
import { checkNullNumber, checkNullString } from "../helper/isVal";
import TableDefault from "../components/custom/TableDefault";
import Loader from "../components/custom/Loader";

export default function Report(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState({
    ID: null,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    REPORT_PERIOD: 999,
    REPORT_TITLE: "",
    REPORT_CONTENT: "",
    REPORT_FILE: null,
    REPORT_OLD_ATTACHMENT: "",
    REPORT_ATTACHMENT: "",
  });

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [dropDown, setDropDown] = useState({
    office: [],
    subOffice: [],
    period: [],
  });

  async function getLibrary() {
    try {
      const result1 = (await axios.get("/refOfficeWithHead")).data;
      const result2 = (await axios.get("/refSubOfficeWithHead")).data;
      const result3 = (await axios.get("/refReportPeriod")).data;
      setDropDown({
        office: result1,
        subOffice: result2,
        period: result3,
      });
    } catch (error) {}
  }

  function download(url) {
    //console.log(url, "url");
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = url.split("/").pop();
        link.click();
      })
      .catch(console.error);
  }
  const [filterList, setFilterList] = useState({
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
    REPORT_PERIOD: 999,
  });
  const [filteredList, setFilteredList] = useState([]);
  const columns = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Аймаг, дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Тайлан хугацаа",
      accessor: "PERIOD_NAME",
    },
    {
      Header: "Тайлангийн агуулга",
      accessor: "REPORT_TITLE",
    },
    {
      Header: "Дэлгэрэнгүй тайлбар",
      accessor: "REPORT_CONTENT",
    },
    {
      Header: "Хавсралт",
      accessor: (row, index) => {
        return (
          !checkNullString(row.REPORT_ATTACHMENT) && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                download(
                  process.env.REACT_APP_BASE_URL + "/" + row.REPORT_ATTACHMENT
                )
              }
            >
              <FiDownload />
            </Button>
          )
        );
      },
    },
    {
      Header: "Үүсгэсэн огноо",
      accessor: (row, index) => {
        return DateFormat(row.CREATED_DATE);
      },
    },
    {
      Header: "Шинэчилсэн огноо",
      accessor: (row, index) => {
        return DateFormat(row.UPDATED_DATE);
      },
    },

    {
      Header: "Үйлдэл",
      accessor: (row) => (
        <div className="text-center">
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => editReport(row)}
            >
              <FiEdit />
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => removeRow(row.ID)}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const [dataList, setDataList] = useState([]);
  const filterData = () => {
    setFilteredList(dataList);

    if (filterList.OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.OFFICE_ID === filterList.OFFICE_ID)
      );
    }
    if (filterList.SUB_OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.SUB_OFFICE_ID === filterList.SUB_OFFICE_ID)
      );
    }
    if (filterList.REPORT_PERIOD !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.REPORT_PERIOD === filterList.REPORT_PERIOD)
      );
    }
  };
  const excelHeader = ["№", "Аймаг, дүүрэг", "Сум, хороо"];
  const rows = filteredList.map((row, i) => ({
    index: i + 1,
    OFFICE_NAME: row.OFFICE_NAME,
    SUB_OFFICE_NAME: row.SUB_OFFICE_NAME,
  }));

  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);
  const [rowDeletedID, setRowDeletedID] = useState();

  async function detailReport(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              REPORT_PERIOD: 999,
              REPORT_TITLE: "",
              REPORT_CONTENT: "",
              REPORT_OLD_ATTACHMENT: "",
              REPORT_ATTACHMENT: "",
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              REPORT_PERIOD: row.REPORT_PERIOD,
              REPORT_TITLE: row.REPORT_TITLE,
              REPORT_CONTENT: row.REPORT_CONTENT,
              REPORT_OLD_ATTACHMENT: row.REPORT_ATTACHMENT,
              REPORT_ATTACHMENT: row.REPORT_ATTACHMENT,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };
      setDataReport(data);
      handleShowModal();
    } catch (error) {}
  }

  async function removeRow(ID) {
    setRowDeletedID(ID);
    handleShowConfirm();
  }
  async function removeReport() {
    try {
      let data = {
        ID: rowDeletedID,
        deletedBy: userData.user.USER_ID,
      };
      setLoading(true);
      let result = {};
      result = await axios.post("/reportRemove", data);
      alert(result.data.message);
      handleCloseConfirm();
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }
  const editReport = (row) => {
    detailReport(row);
  };
  function requiredReport() {
    if (checkNullNumber(dataReport.OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataReport.SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullNumber(dataReport.REPORT_PERIOD)) {
      alert("Тайлант огноо сонгоно уу");
    } else if (checkNullString(dataReport.REPORT_TITLE)) {
      alert("Тайлангийн агуулга оруулна уу");
    } else {
      return true;
    }
  }
  async function saveReport(event) {
    try {
      if (requiredReport()) {
        setLoading(true);
        let postData = dataReport;
        let fileName = null;
        if (dataReport.REPORT_FILE) {
          let fileNameSplit = dataReport.REPORT_FILE.name.split(".");
          let ext = fileNameSplit.pop();
          fileName = fileNameSplit.join(".") + "_" + Date.now();

          // console.log(fileNameSplit, "fileNameSplit");
          // console.log(fileName, "fileName");
          // console.log(ext, "ext");

          const formData = new FormData();
          formData.append("file", dataReport.REPORT_FILE);
          let resultFile = {};
          resultFile = await axios.post("/upload/report/" + fileName, formData);

          if (resultFile.status === 200) {
            postData.REPORT_ATTACHMENT = resultFile.data.filePath;
            // file delete
            if (!checkNullString(dataReport.REPORT_OLD_ATTACHMENT)) {
              let deletedFileName = dataReport.REPORT_OLD_ATTACHMENT.split("/");
              let resultFileDelete = [];

              resultFileDelete = await axios.delete(
                `/files/report/${deletedFileName.pop()}`
              );
            }
          }
        } else {
          postData.REPORT_ATTACHMENT = postData.REPORT_OLD_ATTACHMENT;
        }
        setDataReport({ ...postData });

        let result = {};
        result = await axios.post("/reportIU", postData);

        alert(result.data.message);
        handleCloseModal();
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      //alert(error);
      alert("25MB-с их хэмжээтэй байна!");
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const result = (
        await axios.post("/reportList", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          REPORT_PERIOD: filterList.REPORT_PERIOD,
          FILTER_OFFICE: filterList.OFFICE_ID,
          FILTER_SUB_OFFICE: filterList.SUB_OFFICE_ID,
          FILTER_PERIOD: filterList.REPORT_PERIOD,
        })
      ).data;
      setDataList(result);
      setFilteredList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase">Тайлан</Card.Title>
            <div className="d-flex">
              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 3 ||
                  userData.user.ROLE_LEVEL_ID === 4 ||
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.OFFICE_ID = parseInt(e.target.value);

                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      )
                    ) {
                      value.SUB_OFFICE_ID = 999;
                    }
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 1 ||
                    userData.user.ROLE_LEVEL_ID === 99
                      ? dropDown.office
                      : userData.user.ROLE_LEVEL_ID === 2
                      ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                      : dropDown.office.filter(
                          (a) =>
                            parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                        )
                  }
                />
              </div>

              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.SUB_OFFICE_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 5
                      ? dropDown.subOffice
                          ?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(filterList.OFFICE_ID)
                          )
                          .filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.SUB_OFFICE_ID)
                          )
                      : dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(filterList.OFFICE_ID)
                        )
                  }
                />
              </div>

              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 3 ||
                  userData.user.ROLE_LEVEL_ID === 4 ||
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.REPORT_PERIOD}
                  onChange={(e) => {
                    let value = filterList;
                    value.REPORT_PERIOD = parseInt(e.target.value);

                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={dropDown.period}
                />
              </div>
              <Button
                variant="light"
                onClick={() => detailReport()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center px-2"
              >
                <FiPlusSquare className="me-2" />
                Нэмэх
              </Button>

              <ExportToExcel
                colData={rows}
                fileName={
                  "АТБ хөтөлбөрийн бүртгэл " + DateFormat(Date.now(), "time")
                }
                colHeader={excelHeader}
              />
            </div>
            <div className="table-responsive p-2">
              <TableDefault columns={columns} data={filteredList} />
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Тайлан</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Аймаг, дүүрэг: <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataReport.OFFICE_ID}
                onChange={(e) => {
                  let value = dataReport;
                  value.OFFICE_ID = e.target.value;
                  if (
                    dropDown.subOffice?.filter(
                      (a) => parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                    ).length < 0
                  ) {
                    value.SUB_OFFICE_ID = null;
                  }
                  setDataReport({ ...value });
                }}
                //options={dropDown.office}
                options={
                  userData.user.ROLE_LEVEL_ID === 1 ||
                  userData.user.ROLE_LEVEL_ID === 99
                    ? dropDown.office
                    : userData.user.ROLE_LEVEL_ID === 2
                    ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                    : dropDown.office.filter(
                        (a) =>
                          parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                      )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Сум, хороо: <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataReport.SUB_OFFICE_ID}
                onChange={(e) => {
                  let value = dataReport;
                  value.SUB_OFFICE_ID = e.target.value;
                  setDataReport({ ...value });
                }}
                // options={dropDown.subOffice?.filter(
                //   (a) =>
                //     parseInt(a.OFFICE_ID) === parseInt(dataReport.OFFICE_ID)
                // )}
                options={
                  userData.user.ROLE_LEVEL_ID === 5
                    ? dropDown.subOffice
                        ?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(dataReport.OFFICE_ID)
                        )
                        .filter(
                          (a) =>
                            parseInt(a.ID) ===
                            parseInt(userData.user.SUB_OFFICE_ID)
                        )
                    : dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) ===
                          parseInt(dataReport.OFFICE_ID)
                      )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Тайлант огноо: <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataReport.REPORT_PERIOD}
                onChange={(e) => {
                  let value = dataReport;
                  value.REPORT_PERIOD = e.target.value;
                  setDataReport({ ...value });
                }}
                options={dropDown.period}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Тайлангийн агуулга: <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataReport.REPORT_TITLE}
                onChange={(e) => {
                  let value = dataReport;
                  value.REPORT_TITLE = e.target.value;
                  setDataReport({ ...value });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Дэлгэрэнгүй тайлбар:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataReport.REPORT_CONTENT}
                onChange={(e) => {
                  let value = dataReport;
                  value.REPORT_CONTENT = e.target.value;
                  setDataReport({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">Хавсралт:</Form.Label>
              {/* <Form.Control
                size="sm"
                value={dataReport.REPORT_ATTACHMENT}
                onChange={(e) => {
                  let value = dataReport;
                  value.REPORT_ATTACHMENT = e.target.value;
                  setDataReport({ ...value });
                }}
              /> */}
              {dataReport.REPORT_OLD_ATTACHMENT}
              <Form.Control
                type="file"
                size="sm"
                onChange={(e) => {
                  let value = dataReport;
                  value.REPORT_FILE = e.target.files[0];
                  setDataReport({ ...value });
                }}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveReport("save")}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseModal}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        show={showConfirm}
        close={handleCloseConfirm}
        remove={() => removeReport()}
      />
      {loading && <Loader />}
    </div>
  );
}
