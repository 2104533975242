import React from "react";
import { Blocks } from "react-loader-spinner";

export default function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        opacity: 0.8,
      }}
    >
      <Blocks
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          margin: 0,
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
        }}
        wrapperClass="blocks-wrapper"
      />
    </div>
  );
}
