import "./App.css";
import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import MainLayout from "./layouts/Main";
import Login from "./pages/Login";
import Elder from "./pages/Elder";
import ElderDetail from "./pages/ElderDetail";
import Event from "./pages/Event";
import FriendlyOrg from "./pages/FriendlyOrg";
import Organization from "./pages/Organization";
import PensionProgram from "./pages/PensionProgram";
import Report from "./pages/Report";
import Survey from "./pages/Survey";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Error from "./pages/Error";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/elder" element={<Elder />} />
          <Route path="/elder/:id" element={<ElderDetail />} />
          <Route path="/event" element={<Event />} />
          <Route path="/friendlyOrg" element={<FriendlyOrg />} />
          <Route path="/org" element={<Organization />} />
          <Route path="/pension" element={<PensionProgram />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/report" element={<Report />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
