import axios from "axios";
import { FiPlusSquare, FiSave, FiLogOut } from "react-icons/fi";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Dropdown from "../components/custom/Dropdown";
import TableReact from "../components/custom/TableReact";
import ConfirmModal from "../components/custom/RemoveConfirm";
import DateFormat from "../helper/DateFormat";
import { useContext } from "react";
import UserContext from "../helper/UserContext";
import { ExportToExcel } from "../helper/ExportToExcel";
import { checkNullNumber, checkNullString } from "../helper/isVal";

export default function Pension(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [dataPension, setDataPension] = useState({
    ID: null,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    ORGANIZATION_NAME: "",
    PROGRAM_PARTICIPANT: null,
    PROGRAM_YEAR: null,
    COVERED_PARTICIPANT: null,
    ELDER_PARTICIPANT: null,
    PROGRAM_DESCRIPTION: "",
    PRE_ELDER_COUNT: null,
  });

  const PENSION_TABLE_HEADER = [
    { name: "Аймаг, дүүрэг", dName: "OFFICE_NAME", type: "string" },
    { name: "Сум, хороо", dName: "SUB_OFFICE_NAME", type: "string" },
    {
      name: "Байгууллага, аж ахуйн нэгжийн нэр",
      dName: "ORGANIZATION_NAME",
      type: "string",
    },
    { name: "Жил", dName: "PROGRAM_YEAR", type: "number" },
    {
      name: "Хөтөлбөрт хамрагдаж байгаа ажилтны тоо",
      dName: "PROGRAM_PARTICIPANT",
      type: "number",
    },
    {
      name: "Сургалтанд хамрагдсан ажилтны тоо",
      dName: "COVERED_PARTICIPANT",
      type: "number",
    },
    {
      name: "Оролцсон ахмадын тоо",
      dName: "ELDER_PARTICIPANT",
      type: "number",
    },
    {
      name: "Хөтөлбөрийн тайлбар",
      dName: "PROGRAM_DESCRIPTION",
      type: "string",
    },
  ];
  const [pensionHeader, setPensionHeader] = useState(PENSION_TABLE_HEADER);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [dropDown, setDropDown] = useState({
    office: [],
    subOffice: [],
  });

  async function getLibrary() {
    try {
      const result1 = (await axios.get("/refOffice")).data;
      const result2 = (await axios.get("/refSubOffice")).data;
      setDropDown({
        office: result1,
        subOffice: result2,
      });
    } catch (error) {}
  }
  const [filterList, setFilterList] = useState({
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
  });
  const [filteredList, setFilteredList] = useState([]);

  const [dataList, setDataList] = useState([]);
  const filterData = () => {
    setFilteredList(dataList);

    if (filterList.OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.OFFICE_ID === filterList.OFFICE_ID)
      );
    }
    if (filterList.SUB_OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.SUB_OFFICE_ID === filterList.SUB_OFFICE_ID)
      );
    }
  };
  const excelHeader = [
    "№",
    "Аймаг, дүүрэг",
    "Сум, хороо",
    "Байгууллага, аж ахуйн нэгжийн нэр",
    "Жил",
    "Хөтөлбөрт хамрагдаж байгаа ажилтны тоо",
    "Сургалтанд хамрагдсан ажилтны тоо",
    "Оролцсон ахмадын тоо",
    "Хөтөлбөрийн тайлбар",
  ];
  const rows = filteredList.map((row, i) => ({
    index: i + 1,
    OFFICE_NAME: row.OFFICE_NAME,
    SUB_OFFICE_NAME: row.SUB_OFFICE_NAME,
    ORGANIZATION_NAME: row.ORGANIZATION_NAME,
    PROGRAM_YEAR: row.PROGRAM_YEAR,
    PROGRAM_PARTICIPANT: row.PROGRAM_PARTICIPANT,
    COVERED_PARTICIPANT: row.COVERED_PARTICIPANT,
    ELDER_PARTICIPANT: row.ELDER_PARTICIPANT,
    PROGRAM_DESCRIPTION: row.PROGRAM_DESCRIPTION,
  }));

  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);
  const [rowDeletedID, setRowDeletedID] = useState();

  async function detailPension(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              ORGANIZATION_NAME: "",
              PROGRAM_PARTICIPANT: null,
              PROGRAM_YEAR: null,
              COVERED_PARTICIPANT: null,
              ELDER_PARTICIPANT: null,
              PROGRAM_DESCRIPTION: "",
              PRE_ELDER_COUNT: null,
              IS_ACTIVE: 1,
              CREATED_BY: 1,
            }
          : {
              ID: row.ID,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              ORGANIZATION_NAME: row.ORGANIZATION_NAME,
              PROGRAM_PARTICIPANT: row.PROGRAM_PARTICIPANT,
              PROGRAM_YEAR: row.PROGRAM_YEAR,
              COVERED_PARTICIPANT: row.COVERED_PARTICIPANT,
              ELDER_PARTICIPANT: row.ELDER_PARTICIPANT,
              PROGRAM_DESCRIPTION: row.PROGRAM_DESCRIPTION,
              PRE_ELDER_COUNT: null,
              IS_ACTIVE: 1,
              CREATED_BY: 1,
            };
      setDataPension(data);
      handleShowModal();
    } catch (error) {}
  }

  async function removeRow(ID) {
    setRowDeletedID(ID);
    handleShowConfirm();
  }
  async function removePension() {
    try {
      let data = {
        ID: rowDeletedID,
        deletedBy: userData.user.USER_ID,
      };
      setLoading(true);
      let result = {};
      result = await axios.post("/regPensionDelete", data);
      alert(result.data.message);
      handleCloseConfirm();
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }
  const editPension = (row) => {
    detailPension(row);
  };
  function requiredPension() {
    if (checkNullNumber(dataPension.OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataPension.SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullString(dataPension.ORGANIZATION_NAME)) {
      alert("Байгууллага, ААН-ийн нэр оруулна уу");
    } else {
      return true;
    }
  }
  async function savePension(action) {
    try {
      if (requiredPension()) {
        setLoading(true);
        let result = {};
        result = await axios.post("/regPensionIU", dataPension);

        alert(result.data.message);
        handleCloseModal();
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      alert(error.response.data.error);
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const result = (
        await axios.post("/regPensionList", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setDataList(result);
      setFilteredList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase">
              Ажилтныг тэтгэвэрт бэлтгэх хөтөлбөртэй байгууллага, аж ахуйн
              нэгжийн бүртгэл
            </Card.Title>
            <div className="d-flex">
              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 3 ||
                  userData.user.ROLE_LEVEL_ID === 4 ||
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.OFFICE_ID = parseInt(e.target.value);

                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      )
                    ) {
                      value.SUB_OFFICE_ID = 999;
                    }
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 1 ||
                    userData.user.ROLE_LEVEL_ID === 99
                      ? dropDown.office
                      : userData.user.ROLE_LEVEL_ID === 2
                      ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                      : dropDown.office.filter(
                          (a) =>
                            parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                        )
                  }
                />
              </div>

              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.SUB_OFFICE_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 5
                      ? dropDown.subOffice
                          ?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(filterList.OFFICE_ID)
                          )
                          .filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.SUB_OFFICE_ID)
                          )
                      : dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(filterList.OFFICE_ID)
                        )
                  }
                />
              </div>
              <Button
                variant="light"
                onClick={() => detailPension()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center px-2"
              >
                <FiPlusSquare className="me-2" />
                Нэмэх
              </Button>

              <ExportToExcel
                colData={rows}
                fileName={
                  "АТБ хөтөлбөрийн бүртгэл " + DateFormat(Date.now(), "time")
                }
                colHeader={excelHeader}
              />
            </div>
            <div className="table-responsive p-2">
              <TableReact
                isAction={true}
                header={pensionHeader}
                data={filteredList}
                editRow={editPension}
                removeRow={removeRow}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ажилтны тэтгэвэрт бэлтгэх хөтөлбөр</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Аймаг, дүүрэг: <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataPension.OFFICE_ID}
                onChange={(e) => {
                  let value = dataPension;
                  value.OFFICE_ID = e.target.value;
                  if (
                    dropDown.subOffice?.filter(
                      (a) => parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                    ).length < 0
                  ) {
                    value.SUB_OFFICE_ID = null;
                  }
                  setDataPension({ ...value });
                }}
                //options={dropDown.office}
                options={
                  userData.user.ROLE_LEVEL_ID === 1 ||
                  userData.user.ROLE_LEVEL_ID === 99
                    ? dropDown.office
                    : userData.user.ROLE_LEVEL_ID === 2
                    ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                    : dropDown.office.filter(
                        (a) =>
                          parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                      )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Сум, хороо: <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataPension.SUB_OFFICE_ID}
                onChange={(e) => {
                  let value = dataPension;
                  value.SUB_OFFICE_ID = e.target.value;
                  setDataPension({ ...value });
                }}
                // options={dropDown.subOffice?.filter(
                //   (a) =>
                //     parseInt(a.OFFICE_ID) === parseInt(dataPension.OFFICE_ID)
                // )}
                options={
                  userData.user.ROLE_LEVEL_ID === 5
                    ? dropDown.subOffice
                        ?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(dataPension.OFFICE_ID)
                        )
                        .filter(
                          (a) =>
                            parseInt(a.ID) ===
                            parseInt(userData.user.SUB_OFFICE_ID)
                        )
                    : dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) ===
                          parseInt(dataPension.OFFICE_ID)
                      )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Байгууллага, ААН-ийн нэр: <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataPension.ORGANIZATION_NAME}
                onChange={(e) => {
                  let value = dataPension;
                  value.ORGANIZATION_NAME = e.target.value;
                  setDataPension({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column className="col-form-label-sm">
                Хөтөлбөрийн жил:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataPension.PROGRAM_YEAR}
                  onChange={(e) => {
                    let value = dataPension;
                    value.PROGRAM_YEAR = e.target.value;
                    setDataPension({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column className="col-form-label-sm">
                Хөтөлбөрт хамрагдаж байгаа ажилтны тоо:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataPension.PROGRAM_PARTICIPANT}
                  onChange={(e) => {
                    let value = dataPension;
                    value.PROGRAM_PARTICIPANT = e.target.value;
                    setDataPension({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column className="col-form-label-sm">
                Сургалтанд хамрагдсан ажилтны тоо:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataPension.COVERED_PARTICIPANT}
                  onChange={(e) => {
                    let value = dataPension;
                    value.COVERED_PARTICIPANT = e.target.value;
                    setDataPension({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column className="col-form-label-sm">
                Оролцсон ахмад настны тоо:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataPension.ELDER_PARTICIPANT}
                  onChange={(e) => {
                    let value = dataPension;
                    value.ELDER_PARTICIPANT = e.target.value;
                    setDataPension({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="col-form-label-sm">
                Хөтөлбөрийн нэмэлт тайлбар:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataPension.PROGRAM_DESCRIPTION}
                onChange={(e) => {
                  let value = dataPension;
                  value.PROGRAM_DESCRIPTION = e.target.value;
                  setDataPension({ ...value });
                }}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => savePension("save")}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseModal}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        show={showConfirm}
        close={handleCloseConfirm}
        remove={() => removePension()}
      />
    </div>
  );
}
