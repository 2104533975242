import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Dropdown from "../components/custom/Dropdown";
import ConfirmModal from "../components/custom/RemoveConfirm";
import DateFormat from "../helper/DateFormat";
import { validRegister } from "../helper/Regex.js";
import { useNavigate, useParams } from "react-router-dom";
import TableReact from "../components/custom/TableReact";
import {
  FiArrowLeft,
  FiLogIn,
  FiLogOut,
  FiPlusSquare,
  FiPrinter,
  FiSave,
} from "react-icons/fi";
import PrintClass from "../components/PrintClass";
import IsAuth from "../helper/IsAuth";
import UserContext from "../helper/UserContext";
import { checkNullNumber, checkNullString } from "../helper/isVal";
import Loader from "../components/custom/Loader";

export default function ElderDetail(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();

  //const [disabled, setDisabled] = useState(true);
  const [displayNone, setDisplayNone] = useState(true);
  const [displayNoneDependence, setDisplayNoneDependence] = useState(true);
  const [displayNoneWorkNeed, setDisplayNoneWorkNeed] = useState(false);
  const [enabledSubOffice, setEnabledSubOffice] = useState(true);
  const [confirmAction, setConfirmAction] = useState("");

  const AWARD_TABLE_HEADER = [
    { name: "Ангилал", dName: "AWARD_TYPE_NAME", type: "string" },
    { name: "Дэд ангилал", dName: "AWARD_SUB_TYPE_NAME", type: "string" },
    { name: "Шагналын нэр", dName: "AWARD_NAME", type: "string" },
    { name: "Тайлбар", dName: "AWARD_DESCRIPTION", type: "string" },
  ];
  const [awardHeader, setAwardHeader] = useState(AWARD_TABLE_HEADER);

  const SUPPLY_TABLE_HEADER = [
    { name: "Огноо", dName: "SUPPLY_DATE", type: "date" },
    {
      name: "Байгууллагын нэр",
      dName: "DEPENDENCE_ORG_NAME",
      type: "string",
    },
    { name: "Тэтгэмжийн төрөл", dName: "SUPPLY_TYPE_NAME", type: "string" },
    { name: "Тэтгэмж", dName: "SUPPLY_NAME", type: "string" },
    { name: "Жил", dName: "SUPPLY_YEAR", type: "number" },
    { name: "Тайлбар", dName: "SUPPLY_DESCRIPTION", type: "string" },
  ];
  const [supplyHeader, setSupplyHeader] = useState(SUPPLY_TABLE_HEADER);

  const MOVEMENT_TABLE_HEADER = [
    {
      name: "Шилжилт хөдөлгөөний төрөл",
      dName: "MOVEMENT_TYPE_NAME",
      type: "string",
    },
    { name: "Огноо", dName: "MOVEMENT_DATE", type: "date" },
    {
      name: "Шилжүүлсэн аймаг, дүүрэг",
      dName: "SEND_OFFICE_NAME",
      type: "string",
    },
    {
      name: "Шилжүүлсэн сум, хороо",
      dName: "SEND_SUB_OFFICE_NAME",
      type: "string",
    },
    {
      name: "Хүлээн авсан аймаг, дүүрэг",
      dName: "RECEIVE_OFFICE_NAME",
      type: "string",
    },
    {
      name: "Хүлээн авсан сум, хороо",
      dName: "RECEIVE_SUB_OFFICE_NAME",
      type: "string",
    },
    {
      name: "Тайлбар",
      dName: "MOVEMENT_DESCRIPTION",
      type: "string",
    },
  ];
  const [movementHeader, setMovementHeader] = useState(MOVEMENT_TABLE_HEADER);

  const [dropDown, setDropDown] = useState({
    family: [],
    subFamily: [],
    office: [],
    subOffice: [],
    degree: [],
    subDegree: [],
    profession: [],
    care: [],
    subCare: [],
    award: [],
    subAward: [],
    homeType: [],
    employmentType: [],
    employmentSubType: [],
    supplyType: [],
    welfare: [],
    workNeedType: [],
  });

  const [dataElder, setDataElder] = useState({
    ID: null,
    MOVEMENT_ID: null,
    ELDER_STATUS_ID: 1,
    ELDER_LASTNAME: "",
    ELDER_FIRSTNAME: "",
    ELDER_REGISTER_NO: "",
    ELDER_BORN_DATE: new Date(),
    ELDER_GENDER: 999,
    ELDER_FAMILY_ID: 999,
    ELDER_SUB_FAMILY_ID: 999,
    ELDER_PHONE: "",
    ELDER_OFFICE_ID: 999,
    ELDER_SUB_OFFICE_ID: 999,
    ELDER_BUILDING_NO: "",
    ELDER_DEGREE_ID: 999,
    ELDER_SUB_DEGREE_ID: 999,
    ELDER_PROFESSION: "",
    ELDER_PROFESSION_ID: 999,
    ELDER_DEPENDENCE: 999,
    ELDER_DEPENDENCE_TYPE: "",
    ELDER_DEPENDENCE_NAME: "",
    ELDER_WELFARE_ID: 999,
    ELDER_CARE_ID: 999,
    ELDER_SUB_CARE_ID: 999,
    ELDER_HOME_TYPE_ID: 999,
    ELDER_EMPLOYMENT_TYPE_ID: 999,
    ELDER_EMPLOYMENT_SUB_TYPE_ID: 999,
    ELDER_EMPLOYMENT_ORGANIZATION: "",
    ELDER_EMPLOYMENT_ROLE: "",
    ELDER_WORK_NEED: 999,
    ELDER_WORK_NEED_TYPE_ID: 999,
    CREATED_BY: userData.user.USER_ID,
  });
  const [dataAward, setDataAward] = useState({
    ID: null,
    ELDER_ID: dataElder.ID,
    AWARD_TYPE_ID: 999,
    AWARD_TYPE_NAME: "",
    AWARD_SUB_TYPE_ID: 999,
    AWARD_SUB_TYPE_NAME: "",
    AWARD_NAME: "",
    AWARD_DESCRIPTION: "",
    IS_ACTIVE: 1,
    CREATED_BY: userData.user.USER_ID,
  });
  const [dataSupply, setDataSupply] = useState({
    ID: null,
    ELDER_ID: dataElder.ELDER_ID,
    DEPENDENCE_ORG_NAME: "",
    SUPPLY_TYPE_ID: 999,
    SUPPLY_YEAR: 0,
    SUPPLY_DATE: new Date(),
    SUPPLY_ORGANIZATION: "",
    SUPPLY_NAME: "",
    SUPPLY_DESCRIPTION: "",
    IS_ACTIVE: 1,
    CREATED_BY: userData.user.USER_ID,
  });
  const [dataAwardList, setDataAwardList] = useState([]);
  const [dataSupplyList, setDataSupplyList] = useState([]);
  const [dataMovementList, setDataMovementList] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);

  const [dataMovement, setDataMovement] = useState({
    TYPE_ID: 0,
    ELDER_ID: 0,
    MOVEMENT_ID: 0,
    RECEIVE_OFFICE_ID: 999,
    RECEIVE_OFFICE_NAME: "",
    RECEIVE_SUB_OFFICE_ID: 999,
    RECEIVE_SUB_OFFICE_NAME: "",
    MOVEMENT_DESC: "",
    CREATED_BY: userData.user.USER_ID,
  });

  const [showAward, setShowAward] = useState(false);
  const handleCloseAward = () => setShowAward(false);
  const handleShowAward = () => setShowAward(true);

  const [showSupply, setShowSupply] = useState(false);
  const handleCloseSupply = () => setShowSupply(false);
  const handleShowSupply = () => setShowSupply(true);

  const [showMovement, setShowMovement] = useState(false);
  const handleCloseMovement = () => setShowMovement(false);
  const handleShowMovement = () => setShowMovement(true);
  const confirmActionFun = () => {
    switch (confirmAction) {
      case "award":
        saveAward("remove");
        break;
      case "supply":
        saveSupply("remove");
        break;

      default:
        break;
    }
    return;
  };
  const AwardEditRow = (row) => {
    detailAward(row);
  };
  const AwardRemoveRow = (row) => {
    handleShowConfirm();
    let dataAward = {
      ID: row.ID,
      ELDER_ID: dataElder.ID,
      AWARD_TYPE_ID: row.AWARD_TYPE_ID,
      AWARD_SUB_TYPE_ID: row.AWARD_SUB_TYPE_ID,
      AWARD_NAME: row.AWARD_NAME,
      AWARD_DESCRIPTION: row.AWARD_DESCRIPTION,
      IS_ACTIVE: 0,
      CREATED_BY: userData.user.USER_ID,
    };
    setDataAward(dataAward);
    setConfirmAction("award");
  };
  async function detailAward(row) {
    try {
      let dataAward =
        row === undefined
          ? {
              ID: null,
              ELDER_ID: dataElder.ID,
              AWARD_TYPE_ID: 999,
              AWARD_SUB_TYPE_ID: 999,
              AWARD_NAME: "",
              AWARD_DESCRIPTION: "",
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              ELDER_ID: dataElder.ID,
              AWARD_TYPE_ID: row.AWARD_TYPE_ID,
              AWARD_SUB_TYPE_ID: row.AWARD_SUB_TYPE_ID,
              AWARD_NAME: row.AWARD_NAME,
              AWARD_DESCRIPTION: row.AWARD_DESCRIPTION,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };

      setDataAward(dataAward);
      handleShowAward();
    } catch (error) {}
  }
  function requiredFieldAward(action) {
    if (checkNullNumber(dataAward.AWARD_TYPE_ID)) {
      alert("Ангилал сонгоно уу");
    } else if (checkNullString(dataAward.AWARD_NAME)) {
      alert("Шагналын нэр оруулна уу");
    } else {
      return true;
    }
  }
  async function saveAward(action) {
    try {
      if (requiredFieldAward()) {
        setLoading(true);
        let result = {};
        result = await axios.post("/regElderAward", dataAward);

        alert(result.data.message);
        if (action === "remove") handleCloseConfirm();
        else handleCloseAward();
        fetchAward(dataElder.ID);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }

  const SupplyEditRow = (row) => {
    detailSupply(row);
  };
  const SupplyRemoveRow = (row) => {
    handleShowConfirm();
    let data = {
      ID: row.ID,
      ELDER_ID: row.ELDER_ID,
      DEPENDENCE_ORG_NAME: row.DEPENDENCE_ORG_NAME,
      SUPPLY_TYPE_ID: row.SUPPLY_TYPE_ID,
      SUPPLY_YEAR: row.SUPPLY_YEAR,
      SUPPLY_DATE: row.SUPPLY_DATE,
      SUPPLY_ORGANIZATION: row.SUPPLY_ORGANIZATION,
      SUPPLY_NAME: row.SUPPLY_NAME,
      SUPPLY_DESCRIPTION: row.SUPPLY_DESCRIPTION,
      IS_ACTIVE: 0,
      CREATED_BY: userData.user.USER_ID,
    };
    setDataSupply(data);
    setConfirmAction("supply");
  };
  async function detailSupply(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              ELDER_ID: dataElder.ID,
              DEPENDENCE_ORG_NAME: "",
              SUPPLY_TYPE_ID: 999,
              SUPPLY_YEAR: 0,
              SUPPLY_DATE: new Date(),
              SUPPLY_ORGANIZATION: "",
              SUPPLY_NAME: "",
              SUPPLY_DESCRIPTION: "",
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              ELDER_ID: row.ELDER_ID,
              DEPENDENCE_ORG_NAME: row.DEPENDENCE_ORG_NAME,
              SUPPLY_TYPE_ID: row.SUPPLY_TYPE_ID,
              SUPPLY_YEAR: row.SUPPLY_YEAR,
              SUPPLY_DATE: row.SUPPLY_DATE,
              SUPPLY_ORGANIZATION: row.SUPPLY_ORGANIZATION,
              SUPPLY_NAME: row.SUPPLY_NAME,
              SUPPLY_DESCRIPTION: row.SUPPLY_DESCRIPTION,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };

      setDataSupply(data);
      handleShowSupply();
    } catch (error) {}
  }
  function requiredFieldSupply() {
    if (checkNullString(dataSupply.DEPENDENCE_ORG_NAME)) {
      alert("Байгууллагын нэр уу");
    } else if (checkNullNumber(dataSupply.SUPPLY_TYPE_ID)) {
      alert("Тэтгэмжийн төрөл сонгоно уу");
    } else if (checkNullString(dataSupply.SUPPLY_NAME)) {
      alert("Тэтгэмжийн нэр оруулна уу");
    } else if (checkNullNumber(dataSupply.SUPPLY_YEAR)) {
      alert("Жил оруулна уу");
    } else if (checkNullString(dataSupply.SUPPLY_DATE)) {
      alert("Огноо оруулна уу");
    } else {
      return true;
    }
  }
  async function saveSupply(action) {
    try {
      if (requiredFieldSupply()) {
        setLoading(true);
        let result = {};
        result = await axios.post("/regElderSupply", dataSupply);

        alert(result.data.message);
        if (action === "remove") handleCloseConfirm();
        else handleCloseSupply();
        fetchSupply(dataElder.ID);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }

  async function detailMovement(typeId) {
    try {
      setLoading(true);
      const result = (
        await axios.get("/regElderMovement?elderId=" + dataElder.ID)
      ).data;

      if (result === null || result === undefined || result === "") {
        setDataMovement({
          TYPE_ID: typeId,
          ELDER_ID: dataElder.ID,
          MOVEMENT_ID: 0,
          RECEIVE_OFFICE_ID: 999,
          RECEIVE_OFFICE_NAME: "",
          RECEIVE_SUB_OFFICE_ID: 999,
          RECEIVE_SUB_OFFICE_NAME: "",
          MOVEMENT_DESC: "",
          CREATED_BY: userData.user.USER_ID,
        });
      } else {
        setDataMovement({
          TYPE_ID: typeId,
          ELDER_ID: result.ELDER_ID,
          MOVEMENT_ID: result.ID,
          RECEIVE_OFFICE_ID: result.RECEIVE_OFFICE_ID,
          RECEIVE_OFFICE_NAME: result.RECEIVE_OFFICE_NAME,
          RECEIVE_SUB_OFFICE_ID: result.RECEIVE_SUB_OFFICE_ID,
          RECEIVE_SUB_OFFICE_NAME: result.RECEIVE_SUB_OFFICE_NAME,
          MOVEMENT_DESC: result.MOVEMENT_DESCRIPTION,
          CREATED_BY: userData.user.USER_ID,
        });
        // if (
        //   dataMovement.TYPE_ID === 2 &&
        //   result.RECEIVE_SUB_OFFICE_ID !== null
        // ) {
        //   setEnabledSubOffice(false);
        // }
      }

      handleShowMovement();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    getLibrary();
    if (parseInt(id) !== 0) fetchElderOne(id);
    else getElderId();
  }, []);
  const getLibrary = async () => {
    try {
      setLoading(true);
      let result1,
        result2,
        result3,
        result4,
        result5,
        result6,
        result7,
        result8,
        result9,
        result10,
        result11,
        result12,
        result13,
        result14,
        result15,
        result16;

      var refFamily = localStorage.getItem("refFamily");
      var refSubFamily = localStorage.getItem("refSubFamily");
      var refOffice = localStorage.getItem("refOffice");
      var refSubOffice = localStorage.getItem("refSubOffice");
      var refDegree = localStorage.getItem("refDegree");
      var refSubDegree = localStorage.getItem("refSubDegree");
      var refProfession = localStorage.getItem("refProfession");
      var refCare = localStorage.getItem("refCare");
      var refSubCare = localStorage.getItem("refSubCare");
      var refAwardType = localStorage.getItem("refAwardType");
      var refAwardSubType = localStorage.getItem("refAwardSubType");
      var refHomeType = localStorage.getItem("refHomeType");
      var refEmploymentType = localStorage.getItem("refEmploymentType");
      var refEmploymentSubType = localStorage.getItem("refEmploymentSubType");
      var refWelfare = localStorage.getItem("refWelfare");
      var refSupplyType = localStorage.getItem("refSupplyType");
      if (typeof refFamily !== "undefined" && refFamily !== null) {
        result1 = JSON.parse(localStorage.getItem("refFamily"));
      } else {
        result1 = (await axios.get("/refFamily")).data;
        localStorage.setItem("refFamily", JSON.stringify(result1));
      }
      if (typeof refSubFamily !== "undefined" && refSubFamily !== null) {
        result2 = JSON.parse(localStorage.getItem("refSubFamily"));
      } else {
        result2 = (await axios.get("/refSubFamily")).data;
        localStorage.setItem("refSubFamily", JSON.stringify(result2));
      }
      if (typeof refOffice !== "undefined" && refOffice !== null) {
        result3 = JSON.parse(localStorage.getItem("refOffice"));
      } else {
        result3 = (await axios.get("/refOffice")).data;
        localStorage.setItem("refOffice", JSON.stringify(result3));
      }
      if (typeof refSubOffice !== "undefined" && refSubOffice !== null) {
        result4 = JSON.parse(localStorage.getItem("refSubOffice"));
      } else {
        result4 = (await axios.get("/refSubOffice")).data;
        localStorage.setItem("refSubOffice", JSON.stringify(result4));
      }
      if (typeof refDegree !== "undefined" && refDegree !== null) {
        result5 = JSON.parse(localStorage.getItem("refDegree"));
      } else {
        result5 = (await axios.get("/refDegree")).data;
        localStorage.setItem("refDegree", JSON.stringify(result5));
      }
      if (typeof refSubDegree !== "undefined" && refSubDegree !== null) {
        result6 = JSON.parse(localStorage.getItem("refSubDegree"));
      } else {
        result6 = (await axios.get("/refSubDegree")).data;
        localStorage.setItem("refSubDegree", JSON.stringify(result6));
      }
      if (typeof refProfession !== "undefined" && refProfession !== null) {
        result7 = JSON.parse(localStorage.getItem("refProfession"));
      } else {
        result7 = (await axios.get("/refProfession")).data;
        localStorage.setItem("refProfession", JSON.stringify(result7));
      }
      if (typeof refCare !== "undefined" && refCare !== null) {
        result8 = JSON.parse(localStorage.getItem("refCare"));
      } else {
        result8 = (await axios.get("/refCare")).data;
        localStorage.setItem("refCare", JSON.stringify(result8));
      }
      if (typeof refSubCare !== "undefined" && refSubCare !== null) {
        result9 = JSON.parse(localStorage.getItem("refSubCare"));
      } else {
        result9 = (await axios.get("/refSubCare")).data;
        localStorage.setItem("refSubCare", JSON.stringify(result9));
      }
      if (typeof refAwardType !== "undefined" && refAwardType !== null) {
        result10 = JSON.parse(localStorage.getItem("refAwardType"));
      } else {
        result10 = (await axios.get("/refAwardType")).data;
        localStorage.setItem("refAwardType", JSON.stringify(result10));
      }
      if (typeof refAwardSubType !== "undefined" && refAwardSubType !== null) {
        result11 = JSON.parse(localStorage.getItem("refAwardSubType"));
      } else {
        result11 = (await axios.get("/refAwardSubType")).data;
        localStorage.setItem("refAwardSubType", JSON.stringify(result11));
      }
      if (typeof refHomeType !== "undefined" && refHomeType !== null) {
        result12 = JSON.parse(localStorage.getItem("refHomeType"));
      } else {
        result12 = (await axios.get("/refHomeType")).data;
        localStorage.setItem("refHomeType", JSON.stringify(result12));
      }
      if (
        typeof refEmploymentType !== "undefined" &&
        refEmploymentType !== null
      ) {
        result13 = JSON.parse(localStorage.getItem("refEmploymentType"));
      } else {
        result13 = (await axios.get("/refEmploymentType")).data;
        localStorage.setItem("refEmploymentType", JSON.stringify(result13));
      }
      if (
        typeof refEmploymentSubType !== "undefined" &&
        refEmploymentSubType !== null
      ) {
        result14 = JSON.parse(localStorage.getItem("refEmploymentSubType"));
      } else {
        result14 = (await axios.get("/refEmploymentSubType")).data;
        localStorage.setItem("refEmploymentSubType", JSON.stringify(result14));
      }
      if (typeof refWelfare !== "undefined" && refWelfare !== null) {
        result15 = JSON.parse(localStorage.getItem("refWelfare"));
      } else {
        result15 = (await axios.get("/refWelfare")).data;
        localStorage.setItem("refWelfare", JSON.stringify(result15));
      }
      if (typeof refSupplyType !== "undefined" && refSupplyType !== null) {
        result16 = JSON.parse(localStorage.getItem("refSupplyType"));
      } else {
        result16 = (await axios.get("/refSupplyType")).data;
        localStorage.setItem("refSupplyType", JSON.stringify(result16));
      }

      setDropDown({
        family: result1,
        subFamily: result2,
        office: result3,
        subOffice: result4,
        degree: result5,
        subDegree: result6,
        profession: result7,
        care: result8,
        subCare: result9,
        award: result10,
        subAward: result11,
        homeType: result12,
        employmentType: result13,
        employmentSubType: result14,
        welfare: result15,
        supplyType: result16,
        workNeedType: result7,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  async function fetchElderOne(id) {
    try {
      setLoading(true);
      const result = (await axios.get("/regElderId?elderId=" + id)).data;
      setDataElder(result.Elder);
      // setDataAwardList(result.Award);
      // setDataSupplyList(result.Supply);
      setDataMovementList(result.Movement);

      fetchAward(id);
      fetchSupply(id);

      setDisplayNoneDependence(
        result.Elder.ELDER_DEPENDENCE !== 1 ? true : false
      );
      setDisplayNone(
        result.Elder.ELDER_EMPLOYMENT_TYPE_ID !== 2 ? true : false
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  async function fetchAward(id) {
    try {
      const result = (await axios.get("/regElderId?elderId=" + id)).data;
      setDataAwardList(result.Award);
    } catch (error) {}
  }
  async function fetchSupply(id) {
    try {
      const result = (await axios.get("/regElderId?elderId=" + id)).data;
      setDataSupplyList(result.Supply);
    } catch (error) {}
  }
  async function getElderId() {
    try {
      const result = (await axios.get("/seqElderId")).data;
      let value = dataElder;
      value.ID = result.NEXTVAL;
      setDataElder({ ...value });
      id = result.NEXTVAL;
      //console.log("resultttttttt", dataElder.ID);
    } catch (error) {}
  }
  const [checkReg, setCheckReg] = useState(false);
  async function fetchElderCheck(registerno, elderid) {
    try {
      let register = {
        ELDER_REGISTER_NO: "",
        ELDER_ID: "",
      };
      register.ELDER_REGISTER_NO = registerno;
      register.ELDER_ID = elderid;
      const result = await axios.post("/regElderCheck", register);
      if (result.data !== "") {
        alert(
          result.data.ELDER_REGISTER_NO +
            " регистртэй '" +
            result.data.FULL_NAME.toUpperCase() +
            "' нь " +
            result.data.OFFICE_NAME +
            " -н " +
            result.data.SUB_OFFICE_NAME +
            " -д/т бүртгэлтэй байна."
        );
        setCheckReg(false);
      } else setCheckReg(true);
    } catch (error) {
      setCheckReg(false);
    }
  }
  function requiredField() {
    if (checkNullString(dataElder.ELDER_REGISTER_NO)) {
      alert("Регистрийн дугаар оруулна уу");
    } else if (!validRegister.test(dataElder.ELDER_REGISTER_NO)) {
      alert("Регистрийн дугаар буруу байна");
    } else if (checkNullString(dataElder.ELDER_FIRSTNAME)) {
      alert("Нэр оруулна уу");
    } else if (checkNullString(dataElder.ELDER_LASTNAME)) {
      alert("Овог оруулна уу");
    } else if (checkNullNumber(dataElder.ELDER_GENDER)) {
      alert("Хүйс сонгоно уу");
    } else if (checkNullNumber(dataElder.ELDER_OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataElder.ELDER_SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullString(dataElder.ELDER_BUILDING_NO)) {
      alert("Хаяг оруулна уу");
    } else {
      return true;
    }
  }
  const saveData = async () => {
    try {
      fetchElderCheck(dataElder.ELDER_REGISTER_NO, dataElder.ID);
      if (checkReg) {
        if (requiredField()) {
          setLoading(true);
          let result = {};
          let postdata = {
            Elder: dataElder,
          };
          result = await axios.post("/regElderIU", postdata);
          alert(result.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setCheckReg(false);
      alert(error.response.data.error);
    }
  };
  function requiredFieldMovement() {
    if (checkNullNumber(dataMovement.RECEIVE_OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataMovement.RECEIVE_SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullString(dataMovement.MOVEMENT_DESC)) {
      alert("Тайлбар оруулна уу");
    } else {
      return true;
    }
  }
  const saveMovement = async () => {
    try {
      if (requiredFieldMovement()) {
        setLoading(true);
        let result = {};

        result = await axios.post("/regElderChange", dataMovement);

        alert(result.data.message);
        handleCloseMovement();
        //fetchElderOne(dataElder.ID);

        setLoading(false);
        navigate("/elder");
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title>
              <div className="d-flex align-items-center">
                <Button
                  onClick={() => navigate("/elder")}
                  size="sm"
                  className="me-2"
                >
                  <FiArrowLeft />
                </Button>
                <div className="me-auto">
                  <span className="text-uppercase">Ахмадын бүртгэл</span>
                </div>
                {userData.user.ROLE_LEVEL_ID !== 1 && (
                  <>
                    {dataElder.ELDER_STATUS_ID !== 3 &&
                    dataElder.MOVEMENT_ID === null ? (
                      <Button
                        variant="warning"
                        onClick={() => detailMovement(1)}
                        size="sm"
                        className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                      >
                        <FiLogOut className="me-2" />
                        Шилжүүлэх
                      </Button>
                    ) : null}
                    {dataElder.MOVEMENT_ID === null ||
                    dataElder.ELDER_STATUS_ID === 3 ? null : (
                      <Button
                        variant="success"
                        onClick={() => detailMovement(2)}
                        size="sm"
                        className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                      >
                        <FiLogIn className="me-2" />
                        Хүлээн авах
                      </Button>
                    )}
                  </>
                )}
                {/* <PrintClass data={dataElder} /> */}
              </div>
            </Card.Title>
            <Form>
              <h3>Үндсэн мэдээлэл</h3>
              <hr />
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Регистрийн дугаар: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    value={dataElder.ELDER_REGISTER_NO}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_REGISTER_NO = e.target.value;
                      setDataElder({ ...value });
                    }}
                    size="sm"
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Овог: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataElder.ELDER_LASTNAME}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_LASTNAME = e.target.value;
                      setDataElder({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Нэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataElder.ELDER_FIRSTNAME}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_FIRSTNAME = e.target.value;
                      setDataElder({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Төрсөн огноо:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="date"
                    size="sm"
                    value={DateFormat(dataElder.ELDER_BORN_DATE)}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_BORN_DATE = e.target.value;
                      setDataElder({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Ам бүл:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_FAMILY_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_FAMILY_ID = e.target.value;
                      if (
                        dropDown.subFamily?.filter(
                          (a) =>
                            parseInt(a.FAMILY_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.ELDER_SUB_FAMILY_ID = null;
                      }
                      setDataElder({ ...value });
                    }}
                    options={dropDown.family}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Ам бүл 2:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_SUB_FAMILY_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_SUB_FAMILY_ID = e.target.value;
                      setDataElder({ ...value });
                    }}
                    options={dropDown.subFamily?.filter(
                      (a) =>
                        parseInt(a.FAMILY_ID) ===
                        parseInt(dataElder.ELDER_FAMILY_ID)
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Хүйс: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Select
                    size="sm"
                    value={dataElder.ELDER_GENDER}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_GENDER = e.target.value;
                      setDataElder({ ...value });
                    }}
                  >
                    <option value={999}>Сонгоно уу</option>
                    <option value={1}>Эрэгтэй</option>
                    <option value={2}>Эмэгтэй</option>
                  </Form.Select>
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Утас:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataElder.ELDER_PHONE}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_PHONE = e.target.value;
                      setDataElder({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Гэр байшин:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_HOME_TYPE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_HOME_TYPE_ID = e.target.value;
                      setDataElder({ ...value });
                    }}
                    options={dropDown.homeType}
                  />
                </Col>
              </Form.Group>
              <h3>Хаяг</h3>
              <hr />
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Col sm={4}>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="formBasicinput"
                  >
                    <Form.Label column sm={6} className="col-form-label-sm">
                      Аймаг, дүүрэг: <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={6}>
                      <Dropdown
                        value={dataElder.ELDER_OFFICE_ID}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_OFFICE_ID = e.target.value;

                          if (
                            dropDown.subOffice?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(e.target.value).length < 0
                            )
                          ) {
                            value.ELDER_SUB_OFFICE_ID = null;
                          }
                          setDataElder({ ...value });
                        }}
                        //options={dropDown.office}
                        options={
                          userData.user.ROLE_LEVEL_ID === 1 ||
                          userData.user.ROLE_LEVEL_ID === 99
                            ? dropDown.office
                            : userData.user.ROLE_LEVEL_ID === 2
                            ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                            : dropDown.office.filter(
                                (a) =>
                                  parseInt(a.ID) ===
                                  parseInt(userData.user.OFFICE_ID)
                              )
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="formBasicinput"
                  >
                    <Form.Label column sm={6} className="col-form-label-sm">
                      Сум, хороо: <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={6}>
                      <Dropdown
                        value={dataElder.ELDER_SUB_OFFICE_ID}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_SUB_OFFICE_ID = e.target.value;
                          setDataElder({ ...value });
                        }}
                        options={
                          userData.user.ROLE_LEVEL_ID === 5
                            ? dropDown.subOffice
                                ?.filter(
                                  (a) =>
                                    parseInt(a.OFFICE_ID) ===
                                    parseInt(dataElder.ELDER_OFFICE_ID)
                                )
                                .filter(
                                  (a) =>
                                    parseInt(a.ID) ===
                                    parseInt(userData.user.SUB_OFFICE_ID)
                                )
                            : dropDown.subOffice?.filter(
                                (a) =>
                                  parseInt(a.OFFICE_ID) ===
                                  parseInt(dataElder.ELDER_OFFICE_ID)
                              )
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={8}>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="formBasicinput"
                  >
                    <Form.Label column sm={3} className="col-form-label-sm">
                      Хаяг: <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        as="textarea"
                        size="sm"
                        rows={3}
                        value={dataElder.ELDER_BUILDING_NO}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_BUILDING_NO = e.target.value;
                          setDataElder({ ...value });
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Form.Group>
              <h3>Боловсрол</h3>
              <hr />

              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={1} className="col-form-label-sm">
                  Боловсрол:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_DEGREE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_DEGREE_ID = e.target.value;
                      if (
                        dropDown.subDegree.filter(
                          (a) =>
                            parseInt(a.DEGREE_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.ELDER_SUB_DEGREE_ID = null;
                      }
                      setDataElder({ ...value });
                    }}
                    options={dropDown.degree}
                  />
                </Col>
                <Form.Label column sm={1} className="col-form-label-sm">
                  Зэрэг:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_SUB_DEGREE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_SUB_DEGREE_ID = e.target.value;
                      setDataElder({ ...value });
                    }}
                    options={dropDown.subDegree?.filter(
                      (a) =>
                        parseInt(a.DEGREE_ID) ===
                        parseInt(dataElder.ELDER_DEGREE_ID)
                    )}
                  />
                </Col>

                <Form.Label column sm={1} className="col-form-label-sm">
                  Мэргэжил:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataElder.ELDER_PROFESSION}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_PROFESSION = e.target.value;
                      setDataElder({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={1} className="col-form-label-sm">
                  Бүлэг:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_PROFESSION_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_PROFESSION_ID = e.target.value;
                      setDataElder({ ...value });
                    }}
                    options={dropDown.profession}
                  />
                </Col>
              </Form.Group>
              <h3>Хөдөлмөр эрхлэлт</h3>
              <hr />
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Хөдөлмөр эрхлэлт:
                </Form.Label>
                <Col sm={2} className="mb-2">
                  <Dropdown
                    value={dataElder.ELDER_EMPLOYMENT_TYPE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_EMPLOYMENT_TYPE_ID = e.target.value;
                      setDisplayNone(
                        parseInt(e.target.value) === 1 ||
                          parseInt(e.target.value) === 3
                          ? false
                          : true
                      );
                      if (
                        dropDown.employmentSubType.filter(
                          (a) =>
                            parseInt(a.EMPLOYMENT_TYPE_ID) ===
                            parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.ELDER_EMPLOYMENT_SUB_TYPE_ID = null;
                      }

                      setDataElder({ ...value });
                    }}
                    options={dropDown.employmentType}
                  />
                </Col>
                {!displayNone ? (
                  <>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Дэд ангилал:
                    </Form.Label>
                    <Col sm={2}>
                      <Dropdown
                        value={dataElder.ELDER_EMPLOYMENT_SUB_TYPE_ID}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_EMPLOYMENT_SUB_TYPE_ID = e.target.value;
                          setDataElder({ ...value });
                        }}
                        options={dropDown.employmentSubType?.filter(
                          (a) =>
                            parseInt(a.EMPLOYMENT_TYPE_ID) ===
                            parseInt(dataElder.ELDER_EMPLOYMENT_TYPE_ID)
                        )}
                      />
                    </Col>
                    <Form.Label
                      column
                      sm={2}
                      className="col-form-label-sm mb-2"
                    >
                      Байгууллагын нэр:
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control
                        type="input"
                        size="sm"
                        value={dataElder.ELDER_EMPLOYMENT_ORGANIZATION}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_EMPLOYMENT_ORGANIZATION = e.target.value;
                          setDataElder({ ...value });
                        }}
                      />
                    </Col>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Албан тушаал:
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control
                        type="input"
                        size="sm"
                        value={dataElder.ELDER_EMPLOYMENT_ROLE}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_EMPLOYMENT_ROLE = e.target.value;
                          setDataElder({ ...value });
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                <Form.Label column sm={2} className="col-form-label-sm">
                  Хөдөлмөр эрхлэх хүсэлтэй эсэх:
                </Form.Label>
                <Col sm={2} className="mb-2">
                  <Form.Select
                    size="sm"
                    value={dataElder.ELDER_WORK_NEED}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_WORK_NEED = e.target.value;
                      setDataElder({ ...value });
                      setDisplayNoneWorkNeed(
                        parseInt(e.target.value) === 1 ? true : false
                      );
                    }}
                  >
                    <option value={999}>Сонгоно уу</option>
                    <option value={1}>Тийм</option>
                    <option value={2}>Үгүй</option>
                  </Form.Select>
                </Col>
                {displayNoneWorkNeed && (
                  <>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Сонирхож буй салбар:
                    </Form.Label>
                    <Col sm={2} className="mb-2">
                      <Dropdown
                        size="sm"
                        value={dataElder.ELDER_WORK_NEED_TYPE_ID}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_WORK_NEED_TYPE_ID = e.target.value;
                          setDataElder({ ...value });
                        }}
                        options={dropDown.workNeedType}
                      ></Dropdown>
                    </Col>
                  </>
                )}
              </Form.Group>
              <h3>Тэтгэврийн байдал</h3>
              <hr />
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Тэтгэврийн төрөл:
                </Form.Label>
                <Col sm={4}>
                  <Dropdown
                    value={dataElder.ELDER_WELFARE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_WELFARE_ID = e.target.value;

                      setDataElder({ ...value });
                    }}
                    options={dropDown.welfare}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Асаргаа:
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_CARE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_CARE_ID = e.target.value;

                      if (
                        dropDown.subCare.filter(
                          (a) =>
                            parseInt(a.CARE_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.ELDER_SUB_CARE_ID = null;
                      }
                      setDataElder({ ...value });
                    }}
                    options={dropDown.care}
                  />
                </Col>
                <Col sm={2}>
                  <Dropdown
                    value={dataElder.ELDER_SUB_CARE_ID}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_SUB_CARE_ID = e.target.value;
                      setDataElder({ ...value });
                    }}
                    options={dropDown.subCare?.filter(
                      (a) =>
                        parseInt(a.CARE_ID) ===
                        parseInt(dataElder.ELDER_CARE_ID)
                    )}
                  />
                </Col>
              </Form.Group>
              <h3>Харьяалал</h3>
              <hr />
              <Form.Group as={Row} className="mb-2" controlId="formBasicinput">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Харьяалалтай эсэх:
                </Form.Label>
                <Col sm={2}>
                  <Form.Select
                    size="sm"
                    value={dataElder.ELDER_DEPENDENCE}
                    onChange={(e) => {
                      let value = dataElder;
                      value.ELDER_DEPENDENCE = e.target.value;
                      setDataElder({ ...value });
                      setDisplayNoneDependence(
                        parseInt(e.target.value) === 1 ? false : true
                      );
                    }}
                  >
                    <option value={999}>Сонгоно уу</option>
                    <option value={1}>Тийм</option>
                    <option value={2}>Үгүй</option>
                  </Form.Select>
                </Col>
                {!displayNoneDependence && (
                  <>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Байгууллагын төрөл:
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Select
                        size="sm"
                        value={dataElder.ELDER_DEPENDENCE_TYPE}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_DEPENDENCE_TYPE = e.target.value;
                          setDataElder({ ...value });
                        }}
                      >
                        <option value={999}>Сонгоно уу</option>
                        <option value={"Төрийн байгууллага"}>
                          Төрийн байгууллага
                        </option>
                        <option value={"Төрийн бус байгууллага"}>
                          Төрийн бус байгууллага
                        </option>
                      </Form.Select>
                    </Col>
                  </>
                )}
                {!displayNoneDependence && (
                  <>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Байгууллагын нэр:
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control
                        type="input"
                        size="sm"
                        value={dataElder.ELDER_DEPENDENCE_NAME}
                        onChange={(e) => {
                          let value = dataElder;
                          value.ELDER_DEPENDENCE_NAME = e.target.value;
                          setDataElder({ ...value });
                        }}
                      />
                    </Col>
                  </>
                )}
              </Form.Group>
              <h3>Шагнал</h3>
              <hr />
              <div className="mb-4">
                {(IsAuth(userData, 202) || dataElder.ELDER_STATUS_ID === 3) &&
                dataElder.MOVEMENT_ID === null ? (
                  <div className="d-flex justify-content-end mb-2">
                    <Button
                      variant="success"
                      onClick={() => detailAward()}
                      size="sm"
                      className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                    >
                      <FiPlusSquare className="me-2" />
                      Нэмэх
                    </Button>
                  </div>
                ) : null}
                <TableReact
                  isAction={
                    (IsAuth(userData, 202) ||
                      dataElder.ELDER_STATUS_ID === 3) &&
                    dataElder.MOVEMENT_ID === null
                      ? true
                      : false
                  }
                  header={awardHeader}
                  data={dataAwardList}
                  editRow={AwardEditRow}
                  removeRow={AwardRemoveRow}
                />
              </div>

              <h3>Тэтгэмж</h3>
              <hr />
              <div className="mb-4">
                {(IsAuth(userData, 202) || dataElder.ELDER_STATUS_ID === 3) &&
                dataElder.MOVEMENT_ID === null ? (
                  <div className="d-flex justify-content-end mb-2">
                    <Button
                      variant="success"
                      onClick={() => detailSupply()}
                      size="sm"
                      className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                    >
                      <FiPlusSquare className="me-2" />
                      Нэмэх
                    </Button>
                  </div>
                ) : null}
                <TableReact
                  isAction={
                    (IsAuth(userData, 202) ||
                      dataElder.ELDER_STATUS_ID === 3) &&
                    dataElder.MOVEMENT_ID === null
                      ? true
                      : false
                  }
                  header={supplyHeader}
                  data={dataSupplyList}
                  editRow={SupplyEditRow}
                  removeRow={SupplyRemoveRow}
                />
              </div>
              <h3>Шилжилт хөдөлгөөн</h3>
              <hr />
              <div className="mb-4">
                <TableReact
                  isAction={false}
                  header={movementHeader}
                  data={dataMovementList}
                />
              </div>

              <div className="d-flex justify-content-end">
                {(IsAuth(userData, 202) || dataElder.ELDER_STATUS_ID !== 1) &&
                dataElder.MOVEMENT_ID === null ? (
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => saveData()}
                    size="sm"
                    className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                  >
                    <FiSave className="me-2" />
                    Хадгалах
                  </Button>
                ) : null}
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => navigate("/elder")}
                  size="sm"
                  className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                >
                  <FiLogOut className="me-2" />
                  Хаах
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Modal show={showAward} onHide={handleCloseAward}>
        <Modal.Header closeButton>
          <Modal.Title>Шагнал</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="col-form-label-sm">
                Ангилал <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataAward.AWARD_TYPE_ID}
                onChange={(e) => {
                  let value = dataAward;
                  value.AWARD_TYPE_ID = e.target.value;
                  value.AWARD_TYPE_NAME = e.target[e.target.selectedIndex].text;
                  if (
                    dropDown.subAward?.filter(
                      (a) => parseInt(a.AWARD_ID) === parseInt(e.target.value)
                    ).length < 0
                  ) {
                    value.AWARD_SUB_TYPE_ID = null;
                    value.AWARD_SUB_TYPE_NAME = null;
                  }
                  setDataAward({ ...value });
                }}
                options={dropDown.award}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="col-form-label-sm">Дэд ангилал</Form.Label>
              <Dropdown
                value={dataAward.AWARD_SUB_TYPE_ID}
                onChange={(e) => {
                  let value = dataAward;
                  value.AWARD_SUB_TYPE_ID = e.target.value;
                  value.AWARD_SUB_TYPE_NAME =
                    e.target[e.target.selectedIndex].text;
                  setDataAward({ ...value });
                }}
                options={dropDown.subAward?.filter(
                  (a) =>
                    parseInt(a.AWARD_TYPE_ID) ===
                    parseInt(dataAward.AWARD_TYPE_ID)
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Шагналын нэр <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="input"
                size="sm"
                value={dataAward.AWARD_NAME}
                onChange={(e) => {
                  let value = dataAward;
                  value.AWARD_NAME = e.target.value;
                  setDataAward({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">Тайлбар</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataAward.AWARD_DESCRIPTION}
                onChange={(e) => {
                  let value = dataAward;
                  value.AWARD_DESCRIPTION = e.target.value;
                  setDataAward({ ...value });
                }}
              />
            </Form.Group>

            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveAward("save")}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseAward}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showSupply} onHide={handleCloseSupply}>
        <Modal.Header closeButton>
          <Modal.Title>Тэтгэмж</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Байгууллагын нэр <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="input"
                size="sm"
                value={dataSupply.DEPENDENCE_ORG_NAME}
                onChange={(e) => {
                  let value = dataSupply;
                  value.DEPENDENCE_ORG_NAME = e.target.value;
                  setDataSupply({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Тэтгэмжийн төрөл <span className="text-danger">*</span>
              </Form.Label>
              <Dropdown
                value={dataSupply.SUPPLY_TYPE_ID}
                onChange={(e) => {
                  let value = dataSupply;
                  value.SUPPLY_TYPE_ID = e.target.value;
                  setDataSupply({ ...value });
                }}
                options={dropDown.supplyType}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Тэтгэмжийн нэр <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="input"
                size="sm"
                value={dataSupply.SUPPLY_NAME}
                onChange={(e) => {
                  let value = dataSupply;
                  value.SUPPLY_NAME = e.target.value;
                  setDataSupply({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Жил <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                size="sm"
                value={dataSupply.SUPPLY_YEAR}
                onChange={(e) => {
                  let value = dataSupply;
                  value.SUPPLY_YEAR = e.target.value;
                  setDataSupply({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Огноо <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                size="sm"
                value={DateFormat(dataSupply.SUPPLY_DATE)}
                onChange={(e) => {
                  let value = dataSupply;
                  value.SUPPLY_DATE = e.target.value;
                  setDataSupply({ ...value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="col-form-label-sm">Тайлбар</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataSupply.SUPPLY_DESCRIPTION}
                onChange={(e) => {
                  let value = dataSupply;
                  value.SUPPLY_DESCRIPTION = e.target.value;
                  setDataMovement({ ...value });
                }}
              />
            </Form.Group>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveSupply("save")}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseSupply}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showMovement} onHide={handleCloseMovement}>
        <Modal.Header closeButton>
          <Modal.Title>Шилжилт хөдөлгөөн</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="col-form-label-sm">
                Аймаг, Дүүрэг <span className="text-danger">*</span>
              </Form.Label>
              {dataMovement.TYPE_ID === 1 ? (
                <Dropdown
                  value={dataMovement.RECEIVE_OFFICE_ID}
                  onChange={(e) => {
                    let value = dataMovement;
                    value.RECEIVE_OFFICE_ID = e.target.value;
                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      ).length < 0
                    ) {
                      value.RECEIVE_SUB_OFFICE_ID = null;
                    }
                    setDataMovement({ ...value });
                  }}
                  options={dropDown.office}
                />
              ) : (
                <Form.Control
                  disabled
                  size="sm"
                  value={dataMovement.RECEIVE_OFFICE_NAME}
                />
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label className="col-form-label-sm">
                Сум, Хороо <span className="text-danger">*</span>
              </Form.Label>
              {enabledSubOffice ? (
                <Dropdown
                  value={dataMovement.RECEIVE_SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = dataMovement;
                    value.RECEIVE_SUB_OFFICE_ID = e.target.value;
                    setDataMovement({ ...value });
                  }}
                  // options={dropDown.subOffice?.filter(
                  //   (a) =>
                  //     parseInt(a.OFFICE_ID) ===
                  //     parseInt(dataMovement.RECEIVE_OFFICE_ID)
                  // )}
                  options={
                    dataMovement.TYPE_ID === 2
                      ? userData.user.ROLE_LEVEL_ID === 5
                        ? dropDown.subOffice
                            ?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(dataMovement.RECEIVE_OFFICE_ID)
                            )
                            .filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.SUB_OFFICE_ID)
                            )
                        : dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(dataMovement.RECEIVE_OFFICE_ID)
                          )
                      : dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(dataMovement.RECEIVE_OFFICE_ID)
                        )
                  }
                />
              ) : (
                <Form.Control
                  disabled
                  size="sm"
                  value={dataMovement.RECEIVE_SUB_OFFICE_NAME}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="col-form-label-sm">
                Тайлбар <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                disabled={dataMovement.TYPE_ID === 1 ? 0 : 1}
                as="textarea"
                rows={3}
                size="sm"
                value={dataMovement.MOVEMENT_DESC}
                onChange={(e) => {
                  let value = dataMovement;
                  value.MOVEMENT_DESC = e.target.value;
                  setDataMovement({ ...value });
                }}
              />
            </Form.Group>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveMovement()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                {dataMovement.TYPE_ID === 1 ? (
                  <>
                    <FiSave className="me-2" />
                    Шилжүүлэх
                  </>
                ) : dataMovement.TYPE_ID === 2 ? (
                  <>
                    <FiSave className="me-2" />
                    Хүлээн авах
                  </>
                ) : null}
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseMovement}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        show={showConfirm}
        close={handleCloseConfirm}
        remove={() => confirmActionFun()}
      />
      {loading && <Loader />}
    </div>
  );
}
