import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Dropdown from "../components/custom/Dropdown";
import TableDefault from "../components/custom/TableDefault";
import DateFormat from "../helper/DateFormat";
import { ExportToExcel } from "../helper/ExportToExcel";
import UserContext from "../helper/UserContext";
import {
  FiCheck,
  FiEye,
  FiKey,
  FiLogOut,
  FiPlusSquare,
  FiSave,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { checkNullNumber, checkNullString } from "../helper/isVal";
import Loader from "../components/custom/Loader";

export default function Settings(props) {
  const userData = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showModalRemove, setShowModalRemove] = useState(false);
  const handleCloseModalRemove = () => setShowModalRemove(false);
  const handleShowModalRemove = () => setShowModalRemove(true);

  const [showModalPassword, setShowModalPassword] = useState(false);
  const handleCloseModalPassword = () => setShowModalPassword(false);
  const handleShowModalPassword = () => setShowModalPassword(true);

  const [dropDown, setDropDown] = useState({
    office: [],
    subOffice: [],
    roleLevel: [],
    systemRole: [],
  });

  const [filterList, setFilterList] = useState({
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    ROLE_LEVEL_ID: 999,
  });
  const [filteredList, setFilteredList] = useState([]);

  const [dataList, setDataList] = useState([]);
  const filterData = () => {
    setFilteredList(dataList);

    if (filterList.OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.OFFICE_ID === filterList.OFFICE_ID)
      );
    }
    if (filterList.SUB_OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.SUB_OFFICE_ID === filterList.SUB_OFFICE_ID)
      );
    }
    if (filterList.ROLE_LEVEL_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.ROLE_LEVEL_ID === filterList.ROLE_LEVEL_ID)
      );
    }
  };
  const [data, setData] = useState({
    USER_ID: null,
    USER_CODE: null,
    USER_REGISTER_NO: null,
    USER_FIRSTNAME: null,
    USER_LASTNAME: null,
    USER_MIDDLENAME: null,
    USER_ADDRESS: null,
    USER_PASSWORD: null,
    USER_CONFIRM_PASSWORD: null,
    USER_PHONE: null,
    USER_EMAIL: null,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    ROLE_LEVEL_ID: 999,
    SYSTEM_ROLE_ID: 999,
    CREATED_BY: userData.user.USER_ID,
  });
  const [dataRemove, setDataRemove] = useState({
    USER_ID: data.USER_ID,
    DELETED_BY: userData.user.USER_ID,
    DELETED_REASON: null,
  });

  const [dataPC, setDataPC] = useState({
    USER_ID: data.USER_ID,
    DELETED_BY: userData.user.USER_ID,
    NEW_PASSWORD: null,
    NEW_CONFIRM_PASSWORD: null,
  });
  const columns = [
    {
      Header: "№",
      accessor: (row, index) => {
        return index + 1;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Харах",
      accessor: (row, index) => {
        return (
          <div className="text-center">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => detailUser(row)}
            >
              <FiEye />
            </Button>
          </div>
        );
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Аймаг, дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Албан тушаал",
      accessor: "ROLE_NAME",
    },
    {
      Header: "Нэвтрэх нэр",
      accessor: "USER_CODE",
    },
    {
      Header: "Овог",
      accessor: "USER_LASTNAME",
    },
    {
      Header: "Нэр",
      accessor: "USER_FIRSTNAME",
    },
    {
      Header: "Регистрын дугаар",
      accessor: "USER_REGISTER_NO",
    },
    {
      Header: "Утас",
      accessor: "USER_PHONE",
    },
    {
      Header: "Мэйл хаяг",
      accessor: "USER_EMAIL",
    },
    {
      Header: "Гэрийн хаяг",
      accessor: "USER_ADDRESS",
    },
  ];
  const excelHeader = [
    "№",
    "Аймаг, Дүүрэг",
    "Сум, Хороо",
    "Албан тушаал",
    "Нэвтрэх нэр",
    "Овог",
    "Нэр",
    "Регистрын дугаар",
    "Утас",
    "Мэйл хаяг",
    "Гэрийн хаяг",
  ];
  const rows = filteredList.map((row, i) => ({
    index: i + 1,
    OFFICE_NAME: row.OFFICE_NAME,
    SUB_OFFICE_NAME: row.SUB_OFFICE_NAME,
    ROLE_NAME: row.ROLE_NAME,
    USER_CODE: row.USER_CODE,
    USER_LASTNAME: row.USER_LASTNAME,
    USER_FIRSTNAME: row.USER_FIRSTNAME,
    USER_REGISTER_NO: row.USER_REGISTER_NO,
    USER_PHONE: row.USER_PHONE,
    USER_EMAIL: row.USER_EMAIL,
    USER_ADDRESS: row.USER_ADDRESS,
  }));

  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData();
  }, []);
  const getLibrary = async () => {
    try {
      const result1 = (await axios.get("/refOffice")).data;
      const result2 = (await axios.get("/refSubOffice")).data;
      const result3 = (await axios.get("/refRoleLevel")).data;
      const result4 = (await axios.get("/regRoleList")).data;

      setDropDown({
        office: result1,
        subOffice: result2,
        roleLevel: result3,
        systemRole: result4,
      });
    } catch (error) {}
  };

  async function fetchData() {
    try {
      setLoading(true);
      const result = (await axios.post("/regUserList")).data;
      setDataList(result);
      setFilteredList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function requiredUser() {
    if (checkNullString(data.USER_LASTNAME)) {
      alert("Овог оруулна уу");
    } else if (checkNullString(data.USER_FIRSTNAME)) {
      alert("Нэр оруулна уу");
    } else if (checkNullString(data.USER_REGISTER_NO)) {
      alert("Регистрийн дугаар оруулна уу");
    } else if (checkNullString(data.USER_PHONE)) {
      alert("Утас оруулна уу");
    } else if (checkNullNumber(data.SYSTEM_ROLE_ID)) {
      alert("Албан тушаал сонгоно уу");
    } else if (checkNullString(data.USER_PASSWORD) && data.USER_ID === null) {
      alert("Нууц үг оруулна уу");
    } else if (
      checkNullString(data.USER_CONFIRM_PASSWORD) &&
      data.USER_ID === null
    ) {
      alert("Нууц үг /давтах/ оруулна уу");
    } else if (
      data.USER_PASSWORD.localeCompare(data.USER_CONFIRM_PASSWORD) !== 0 &&
      data.USER_ID === null
    ) {
      alert("Нууц үг /давтах/ буруу байна");
    } else {
      return true;
    }
  }
  const saveData = async () => {
    try {
      if (requiredUser()) {
        setLoading(true);
        let result = {};
        let postSaveData = {
          ID: data.USER_ID,
          USER_REGISTER_NO: data.USER_REGISTER_NO,
          USER_LASTNAME: data.USER_LASTNAME,
          USER_FIRSTNAME: data.USER_FIRSTNAME,
          USER_ADDRESS: data.USER_ADDRESS,
          USER_PASSWORD: data.USER_PASSWORD,
          USER_PHONE: data.USER_PHONE,
          USER_EMAIL: data.USER_EMAIL,
          OFFICE_ID: data.OFFICE_ID,
          SUB_OFFICE_ID: data.SUB_OFFICE_ID,
          ROLE_ID: data.SYSTEM_ROLE_ID,
          CREATED_BY: userData.user.USER_ID,
        };
        result = await axios.post("/regUserIU", postSaveData);

        alert(result.data.message);
        handleCloseModal();
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };
  function requiredUserRemove() {
    if (checkNullString(dataRemove.DELETED_REASON)) {
      alert("Шалтгаан оруулна уу");
    } else {
      return true;
    }
  }
  const removeData = async () => {
    try {
      if (requiredUserRemove()) {
        setLoading(true);
        let result = {};

        let postData = {
          USER_ID: data.USER_ID,
          DELETED_REASON: dataRemove.DELETED_REASON,
          DELETED_BY: userData.user.USER_ID,
        };

        result = await axios.post("/regUserDel", postData);

        alert(result.data.message);
        handleCloseModalRemove();
        handleCloseModal();
        fetchData();

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };
  function requiredUserPC() {
    if (checkNullString(dataPC.NEW_PASSWORD)) {
      alert("Нууц үг оруулна уу");
    } else if (checkNullString(dataPC.NEW_CONFIRM_PASSWORD)) {
      alert("Нууц үг /давтах/ оруулна уу");
    } else if (
      dataPC.NEW_PASSWORD.localeCompare(dataPC.NEW_CONFIRM_PASSWORD) !== 0
    ) {
      alert("Нууц үг /давтах/ буруу байна");
    } else {
      return true;
    }
  }
  const pcData = async () => {
    try {
      if (requiredUserPC()) {
        setLoading(true);
        let result = {};

        let postData = {
          USER_ID: data.USER_ID,
          NEW_PASSWORD: dataPC.NEW_PASSWORD,
          DELETED_BY: userData.user.USER_ID,
        };
        result = await axios.post("/regUserPC", postData);

        alert(result.data.message);
        handleCloseModalPassword();
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };
  async function detailUser(row) {
    try {
      let data =
        row === undefined
          ? {
              USER_ID: null,
              USER_CODE: null,
              USER_REGISTER_NO: null,
              USER_FIRSTNAME: null,
              USER_LASTNAME: null,
              USER_MIDDLENAME: null,
              USER_ADDRESS: null,
              USER_PASSWORD: null,
              USER_CONFIRM_PASSWORD: null,
              USER_PHONE: null,
              USER_EMAIL: null,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              ROLE_LEVEL_ID: 999,
              SYSTEM_ROLE_ID: 999,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              USER_ID: row.USER_ID,
              USER_CODE: row.USER_CODE,
              USER_REGISTER_NO: row.USER_REGISTER_NO,
              USER_FIRSTNAME: row.USER_FIRSTNAME,
              USER_LASTNAME: row.USER_LASTNAME,
              USER_MIDDLENAME: row.USER_MIDDLENAME,
              USER_ADDRESS: row.USER_ADDRESS,
              USER_PASSWORD:
                row.USER_PASSWORD !== undefined ? row.USER_PASSWORD : "",
              USER_CONFIRM_PASSWORD:
                row.USER_CONFIRM_PASSWORD !== undefined
                  ? row.USER_PASSWORD
                  : "",
              USER_PHONE: row.USER_PHONE,
              USER_EMAIL: row.USER_EMAIL,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              ROLE_LEVEL_ID: row.ROLE_LEVEL_ID,
              SYSTEM_ROLE_ID: row.SYSTEM_ROLE_ID,
              CREATED_BY: userData.user.USER_ID,
            };

      setData(data);
      handleShowModal();
    } catch (error) {}
  }
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase">
              Хэрэглэгчийн эрхийн удирдлага
            </Card.Title>
            <div className="d-flex ">
              <div className="me-2">
                <Dropdown
                  value={filterList.OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.OFFICE_ID = parseInt(e.target.value);

                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      )
                    ) {
                      value.SUB_OFFICE_ID = 999;
                    }
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={dropDown.office}
                />
              </div>

              <div className="me-2">
                <Dropdown
                  value={filterList.SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.SUB_OFFICE_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={dropDown.subOffice?.filter(
                    (a) =>
                      parseInt(a.OFFICE_ID) === parseInt(filterList.OFFICE_ID)
                  )}
                />
              </div>
              <div className="me-2">
                <Dropdown
                  value={filterList.ROLE_LEVEL_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.ROLE_LEVEL_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={dropDown.roleLevel}
                />
              </div>
              <Button
                variant="light"
                onClick={() => detailUser()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center px-2"
              >
                <FiPlusSquare className="me-2" />
                Нэмэх
              </Button>
              <ExportToExcel
                colData={rows}
                fileName={
                  "Хэрэглэгчийн жагсаалт " + DateFormat(Date.now(), "time")
                }
                colHeader={excelHeader}
              />
            </div>
            <div className="table-responsive p-2">
              <TableDefault columns={columns} data={filteredList} />
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Хэрэглэгчийн бүртгэл</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.USER_ID !== null ? (
            <div className="d-flex justify-content-end mb-3">
              <Button
                variant="danger"
                onClick={() => handleShowModalRemove()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiTrash className="me-2" />
                Устгах
              </Button>
              <Button
                variant="warning"
                onClick={() => handleShowModalPassword()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiKey className="me-2" />
                Нууц үг солих
              </Button>
            </div>
          ) : null}
          <Form>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Овог: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  value={data.USER_LASTNAME}
                  onChange={(e) => {
                    let value = data;
                    value.USER_LASTNAME = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Нэр: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  value={data.USER_FIRSTNAME}
                  onChange={(e) => {
                    let value = data;
                    value.USER_FIRSTNAME = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Регистрийн дугаар: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  value={data.USER_REGISTER_NO}
                  onChange={(e) => {
                    let value = data;
                    value.USER_REGISTER_NO = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Утас: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  value={data.USER_PHONE}
                  onChange={(e) => {
                    let value = data;
                    value.USER_PHONE = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Гэрийн хаяг:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={2}
                  size="sm"
                  value={data.USER_ADDRESS}
                  onChange={(e) => {
                    let value = data;
                    value.USER_ADDRESS = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Цахим шуудан:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  value={data.USER_EMAIL}
                  onChange={(e) => {
                    let value = data;
                    value.USER_EMAIL = e.target.value;
                    setData({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            {data.USER_ID === null ? (
              <>
                <Form.Group className="mb-3 row">
                  <Form.Label column sm={2} className="col-form-label-sm">
                    Нууц үг: <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="password"
                      size="sm"
                      value={data.USER_PASSWORD}
                      onChange={(e) => {
                        let value = data;
                        value.USER_PASSWORD = e.target.value;
                        setData({ ...value });
                      }}
                    />
                  </Col>
                  <Form.Label column sm={2} className="col-form-label-sm">
                    Нууц үг /давтах/: <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="password"
                      size="sm"
                      value={data.USER_CONFIRM_PASSWORD}
                      onChange={(e) => {
                        let value = data;
                        value.USER_CONFIRM_PASSWORD = e.target.value;
                        setData({ ...value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </>
            ) : null}
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Албан тушаалын түвшин:
              </Form.Label>
              <Col sm={4}>
                <Dropdown
                  value={data.ROLE_LEVEL_ID}
                  onChange={(e) => {
                    let value = data;
                    value.ROLE_LEVEL_ID = e.target.value;
                    if (
                      dropDown.systemRole?.filter(
                        (a) =>
                          parseInt(a.ROLE_LEVEL_ID) === parseInt(e.target.value)
                      ).length < 0
                    ) {
                      value.SUB_OFFICE_ID = null;
                    }
                    setData({ ...value });
                  }}
                  options={dropDown.roleLevel}
                />
              </Col>
            </Form.Group>
            {parseInt(data.ROLE_LEVEL_ID) === 999 ||
            parseInt(data.ROLE_LEVEL_ID) === 1 ||
            parseInt(data.ROLE_LEVEL_ID) === 2 ? null : (
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Аймаг, дүүрэг:
                </Form.Label>
                <Col sm={4}>
                  <Dropdown
                    value={data.OFFICE_ID}
                    onChange={(e) => {
                      let value = data;
                      value.OFFICE_ID = e.target.value;
                      if (
                        dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.SUB_OFFICE_ID = null;
                      }
                      setData({ ...value });
                    }}
                    options={
                      parseInt(data.ROLE_LEVEL_ID) === 3 ||
                      parseInt(data.ROLE_LEVEL_ID) === 5
                        ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                        : parseInt(data.ROLE_LEVEL_ID) === 4 ||
                          parseInt(data.ROLE_LEVEL_ID) === 6
                        ? dropDown.office.filter((a) => a.PARENT_ID === 2)
                        : null
                    }
                  />
                </Col>
                {parseInt(data.ROLE_LEVEL_ID) === 5 ||
                parseInt(data.ROLE_LEVEL_ID) === 6 ? (
                  <>
                    <Form.Label column sm={2} className="col-form-label-sm">
                      Сум, хороо:
                    </Form.Label>
                    <Col sm={4}>
                      <Dropdown
                        value={data.SUB_OFFICE_ID}
                        onChange={(e) => {
                          let value = data;
                          value.SUB_OFFICE_ID = e.target.value;
                          setData({ ...value });
                        }}
                        options={dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) === parseInt(data.OFFICE_ID)
                        )}
                      />
                    </Col>
                  </>
                ) : null}
              </Form.Group>
            )}
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Албан тушаал: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Dropdown
                  value={data.SYSTEM_ROLE_ID}
                  onChange={(e) => {
                    let value = data;
                    value.SYSTEM_ROLE_ID = e.target.value;

                    setData({ ...value });
                  }}
                  options={dropDown.systemRole?.filter(
                    (a) =>
                      parseInt(a.ROLE_LEVEL_ID) === parseInt(data.ROLE_LEVEL_ID)
                  )}
                />
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveData()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseModal}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showModalRemove} onHide={handleCloseModalRemove} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Хэрэглэгч устгах</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className="col-form-label-sm">
              Шалтгаан: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                value={dataRemove.DELETED_REASON}
                onChange={(e) => {
                  let value = dataRemove;
                  value.DELETED_REASON = e.target.value;
                  setDataRemove({ ...value });
                }}
              />
            </Col>
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="success"
                type="button"
                onClick={() => removeData()}
                size="sm"
                className="me-2 text-white"
              >
                <FiCheck className="me-2" />
                Баталгаажуулах
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={handleCloseModalRemove}
                size="sm"
                className="me-2 text-white"
              >
                <FiX className="me-2" />
                Цуцлах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalPassword}
        onHide={handleCloseModalPassword}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Хэрэглэгчийн нууц үг солих</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className="col-form-label-sm">
              Нууц үг: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                type="password"
                size="sm"
                value={dataPC.NEW_PASSWORD}
                onChange={(e) => {
                  let value = dataPC;
                  value.NEW_PASSWORD = e.target.value;
                  setDataPC({ ...value });
                }}
              />
            </Col>

            <Form.Label className="col-form-label-sm">
              Нууц үг /давтах/: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                type="password"
                size="sm"
                value={dataPC.NEW_CONFIRM_PASSWORD}
                onChange={(e) => {
                  let value = dataPC;
                  value.NEW_CONFIRM_PASSWORD = e.target.value;
                  setDataPC({ ...value });
                }}
              />
            </Col>
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="success"
                type="button"
                onClick={() => pcData()}
                size="sm"
                className="me-2 text-white"
              >
                <FiCheck className="me-2" />
                Солих
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={handleCloseModalPassword}
                size="sm"
                className="me-2 text-white"
              >
                <FiX className="me-2" />
                Цуцлах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {loading && <Loader />}
    </div>
  );
}
