import axios from "axios";
import {
  FiLogOut,
  FiSave,
  FiPlusSquare,
  FiTrash,
  FiEdit,
  FiUpload,
} from "react-icons/fi";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Dropdown from "../components/custom/Dropdown";
import TableDefault from "../components/custom/TableDefault";
import ConfirmModal from "../components/custom/RemoveConfirm";
import DateFormat from "../helper/DateFormat";
import UserContext from "../helper/UserContext";
import Loader from "../components/custom/Loader";
import { checkNullNumber, checkNullString } from "../helper/isVal";
import { ExportToExcel } from "../helper/ExportToExcel";

export default function Event(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [dataEvent, setDataEvent] = useState({
    ID: null,
    YEAR_ID: 999,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    EVENT_TYPE_ID: 999,
    EVENT_SUB_TYPE_ID: 999,
    EVENT_DATE: new Date(),
    EVENT_NAME: "",
    EVENT_TOPIC: "",
    EVENT_ORGANIZED: "",
    EVENT_PARTICIPANT: null,
    EVENT_COST: null,
    EVENT_INCOME: null,
    EVENT_DESCRIPTION: "",
  });
  const [dataEventFile, setDataEventFile] = useState({
    ID: null,
    EVENT_ID: dataEvent.ID,
    FILE_CATEGORY_ID: 1,
    FILE_TYPE: "",
    FILE_NAME: "",
    FILE_PATH: "",
    FILE_DATE: new Date(),
    FILE_DESCRIPTION: "",
    EVENT_FILE: null,
  });
  const [dataEventFileList, setDataEventFileList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [dropDown, setDropDown] = useState({
    eventType: [],
    eventSubType: [],
    office: [],
    subOffice: [],
    year: [],
  });

  async function getLibrary() {
    try {
      const result1 = (await axios.get("/refEventType")).data;
      const result2 = (await axios.get("/refSubEventType")).data;
      const result3 = (await axios.get("/refOfficeWithHead")).data;
      const result4 = (await axios.get("/refSubOfficeWithHead")).data;
      const result5 = (await axios.get("/refYear")).data;
      setDropDown({
        eventType: result1,
        eventSubType: result2,
        office: result3,
        subOffice: result4,
        year: result5,
      });
    } catch (error) {}
  }

  const [filterList, setFilterList] = useState({
    YEAR_ID: 2,
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
  });
  const [filteredList, setFilteredList] = useState([]);
  const columns = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Огноо",
      accessor: (row, index) => {
        return DateFormat(row.EVENT_DATE);
      },
    },
    {
      Header: "Арга хэмжээний ангилал",
      accessor: "EVENT_TYPE_NAME",
    },
    {
      Header: "Төрөл",
      accessor: "EVENT_SUB_TYPE_NAME",
    },
    {
      Header: "Арга хэмжээний нэр",
      accessor: "EVENT_NAME",
    },
    {
      Header: "Арга хэмжээний сэдэв",
      accessor: "EVENT_TOPIC",
    },
    {
      Header: "Зохион байгуулагч",
      accessor: "EVENT_ORGANIZED",
    },
    {
      Header: "Оролцогчийн тоо",
      accessor: "EVENT_PARTICIPANT",
    },
    {
      Header: "Үйл ажиллагааны зардал",
      accessor: "EVENT_COST",
    },
    {
      Header: "Орлогын дүн",
      accessor: "EVENT_INCOME",
    },
    // {
    //   Header: "Нэмэлт тайлбар",
    //   accessor: "EVENT_DESCRIPTION",
    // },

    {
      Header: "Үйлдэл",
      accessor: (row) => (
        <div className="text-center">
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => editEvent(row)}
            >
              <FiEdit />
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => removeRow(row.ID)}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];
  const [dataList, setDataList] = useState([]);
  const filterData = () => {
    setFilteredList(dataList);

    if (filterList.OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.OFFICE_ID === filterList.OFFICE_ID)
      );
    }
    if (filterList.SUB_OFFICE_ID !== 999) {
      setFilteredList((a) =>
        a.filter((b) => b.SUB_OFFICE_ID === filterList.SUB_OFFICE_ID)
      );
    }
    if (filterList.YEAR_ID !== 999) {
      setFilteredList((a) => a.filter((b) => b.YEAR_ID === filterList.YEAR_ID));
    }
  };
  const excelHeader = [
    "№",
    "Огноо",
    "Арга хэмжээний ангилал",
    "Төрөл",
    "Арга хэмжээний нэр",
    "Арга хэмжээний сэдэв",
    "Зохион байгуулагч",
    "Оролцогчийн тоо",
    "Үйл ажиллагааны зардал",
    "Орлогын дүн",
    "Нэмэлт тайлбар",
  ];
  const rows = filteredList.map((row, i) => ({
    index: i + 1,
    EVENT_DATE: row.EVENT_DATE,
    EVENT_TYPE_NAME: row.EVENT_TYPE_NAME,
    EVENT_SUB_TYPE_NAME: row.EVENT_SUB_TYPE_NAME,
    EVENT_NAME: row.EVENT_NAME,
    EVENT_TOPIC: row.EVENT_TOPIC,
    EVENT_ORGANIZED: row.EVENT_ORGANIZED,
    EVENT_PARTICIPANT: row.EVENT_PARTICIPANT,
    EVENT_COST: row.EVENT_COST,
    EVENT_INCOME: row.EVENT_INCOME,
    EVENT_DESCRIPTION: row.EVENT_DESCRIPTION,
  }));

  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);
  const [rowDeletedID, setRowDeletedID] = useState();

  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData();
  }, [filterList]);
  useEffect(() => {
    getEventFile();
  }, [showModal]);
  async function detailEvent(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              YEAR_ID: 999,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              EVENT_TYPE_ID: 999,
              EVENT_SUB_TYPE_ID: 999,
              EVENT_DATE: new Date(),
              EVENT_NAME: "",
              EVENT_TOPIC: "",
              EVENT_ORGANIZED: "",
              EVENT_PARTICIPANT: null,
              EVENT_COST: null,
              EVENT_INCOME: null,
              EVENT_DESCRIPTION: "",
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              YEAR_ID: row.YEAR_ID,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              EVENT_TYPE_ID: row.EVENT_TYPE_ID,
              EVENT_SUB_TYPE_ID: row.EVENT_SUB_TYPE_ID,
              EVENT_DATE: row.EVENT_DATE,
              EVENT_NAME: row.EVENT_NAME,
              EVENT_TOPIC: row.EVENT_TOPIC,
              EVENT_ORGANIZED: row.EVENT_ORGANIZED,
              EVENT_PARTICIPANT: row.EVENT_PARTICIPANT,
              EVENT_COST: row.EVENT_COST,
              EVENT_INCOME: row.EVENT_INCOME,
              EVENT_DESCRIPTION: row.EVENT_DESCRIPTION,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };

      setDataEvent(data);
      handleShowModal();
    } catch (error) {}
  }

  async function removeRow(ID) {
    setRowDeletedID(ID);
    handleShowConfirm();
  }
  async function removeEvent() {
    try {
      let data = {
        ID: rowDeletedID,
        deletedBy: userData.user.USER_ID,
      };
      setLoading(true);
      let result = {};
      result = await axios.post("/regEventDelete", data);
      alert(result.data.message);
      handleCloseConfirm();
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }
  const editEvent = (row) => {
    detailEvent(row);
  };
  function requiredEvent() {
    if (checkNullNumber(dataEvent.OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataEvent.EVENT_TYPE_ID)) {
      alert("Үйл ажиллагааны ангилал сонгоно уу");
    } else if (checkNullString(dataEvent.EVENT_NAME)) {
      alert("Үйл ажиллагааны нэр оруулна уу");
    } else if (checkNullNumber(dataEvent.YEAR_ID)) {
      alert("Жил сонгоно уу");
    } else {
      return true;
    }
  }
  async function saveEvent(action) {
    try {
      if (requiredEvent()) {
        setLoading(true);
        let result = {};
        result = await axios.post("/regEventIU", dataEvent);

        alert(result.data.message);
        handleCloseModal();
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }
  async function saveEventFile() {
    try {
      setLoading(true);
      let postData = dataEventFile;
      if (dataEventFile.EVENT_FILE) {
        let fileName = null;
        let fileNameSplit = dataEventFile.EVENT_FILE.name.split(".");
        let ext = fileNameSplit.pop();
        fileName = fileNameSplit.join(".") + "_" + Date.now();

        // console.log(fileName, "fileName");
        // console.log(ext, "ext");

        const formData = new FormData();
        formData.append("file", dataEventFile.EVENT_FILE);

        let resultFile = {};
        resultFile = await axios.post("/upload/event/" + fileName, formData);
        if (resultFile.status === 200) {
          postData.EVENT_ID = dataEvent.ID;
          postData.FILE_TYPE = ext;
          postData.FILE_NAME = fileName;
          postData.FILE_PATH = resultFile.data.filePath;
          postData.CREATED_BY = userData.user.USER_ID;
          setDataEventFile({ ...postData });
        }
        setDataEventFile({ ...postData });
      } else {
        alert("Файл хоосон байна!");
      }

      let result = {};
      //console.log(postData, "postData");
      result = await axios.post("/regEventFileAdd", postData);
      alert(result.data.message);
      getEventFile();
      setLoading(false);
      //setDataEvent({});
    } catch (error) {
      //alert(error);
      alert("25MB-с их хэмжээтэй байна!");
      setLoading(false);
    }
  }
  async function removeEventFile(row) {
    try {
      setLoading(true);
      if (window.confirm("Устгах уу!")) {
        // // file delete
        // let deletedFileName = row.FILE_NAME + "." + row.FILE_TYPE;
        // let resultFileDelete = [];
        // resultFileDelete = await axios.delete(
        //   `/files/event/${deletedFileName}`
        // );
        // if (resultFileDelete.status === 200) {
        let result = {};
        result = await axios.post("/regEventFileDelete", {
          ID: row.ID,
          deletedBy: userData.user.USER_ID,
        });

        alert(result.data.message);
        // }
      }
      setLoading(false);
      getEventFile();
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }

  async function getEventFile() {
    try {
      setLoading(true);
      const result = (
        await axios.post("/regEventFileList", {
          EVENT_ID: dataEvent.ID,
        })
      ).data;
      setDataEventFileList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  async function fetchData() {
    try {
      setLoading(true);
      const result = (
        await axios.post("/regEvent", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,

          FILTER_OFFICE: filterList.OFFICE_ID,
          FILTER_SUB_OFFICE: filterList.SUB_OFFICE_ID,
          YEAR_ID: filterList.YEAR_ID,
        })
      ).data;
      setDataList(result);
      setFilteredList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase">
              Үйл ажиллагааны бүртгэл
            </Card.Title>
            <div className="d-flex">
              <div className={`me-2`}>
                <Dropdown
                  value={filterList.YEAR_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.YEAR_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={dropDown.year}
                />
              </div>
              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 3 ||
                  userData.user.ROLE_LEVEL_ID === 4 ||
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.OFFICE_ID = parseInt(e.target.value);

                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      )
                    ) {
                      value.SUB_OFFICE_ID = 999;
                    }
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 1 ||
                    userData.user.ROLE_LEVEL_ID === 99
                      ? dropDown.office
                      : userData.user.ROLE_LEVEL_ID === 2
                      ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                      : dropDown.office.filter(
                          (a) =>
                            parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                        )
                  }
                />
              </div>
              <div
                className={`me-2 ${
                  userData.user.ROLE_LEVEL_ID === 5 ||
                  userData.user.ROLE_LEVEL_ID === 6
                    ? "d-none"
                    : ""
                }`}
              >
                <Dropdown
                  value={filterList.SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = filterList;
                    value.SUB_OFFICE_ID = parseInt(e.target.value);
                    setFilterList({ ...value });
                    filterData();
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 5
                      ? dropDown.subOffice
                          ?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(filterList.OFFICE_ID)
                          )
                          .filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.SUB_OFFICE_ID)
                          )
                      : dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(filterList.OFFICE_ID)
                        )
                  }
                />
              </div>
              <Button
                variant="light"
                onClick={() => detailEvent()}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center px-2"
              >
                <FiPlusSquare className="me-2" />
                Нэмэх
              </Button>
              <ExportToExcel
                colData={rows}
                fileName={
                  "Үйл ажиллагааны бүртгэл " + DateFormat(Date.now(), "time")
                }
                colHeader={excelHeader}
              />
            </div>
            <div className="table-responsive p-2">
              <TableDefault columns={columns} data={filteredList} />
            </div>
          </Card.Body>
        </Card>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Үйл ажиллагааны бүртгэл</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Жил: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={2}>
                <Dropdown
                  value={dataEvent.YEAR_ID}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.YEAR_ID = e.target.value;
                    setDataEvent({ ...value });
                  }}
                  options={dropDown.year}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Аймаг, дүүрэг: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={2}>
                <Dropdown
                  value={dataEvent.OFFICE_ID}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.OFFICE_ID = e.target.value;
                    if (
                      dropDown.subOffice?.filter(
                        (a) =>
                          parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                      ).length < 0
                    ) {
                      value.SUB_OFFICE_ID = null;
                    }
                    setDataEvent({ ...value });
                  }}
                  //options={dropDown.office}
                  options={
                    userData.user.ROLE_LEVEL_ID === 1 ||
                    userData.user.ROLE_LEVEL_ID === 99
                      ? dropDown.office
                      : userData.user.ROLE_LEVEL_ID === 2
                      ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                      : dropDown.office.filter(
                          (a) =>
                            parseInt(a.ID) === parseInt(userData.user.OFFICE_ID)
                        )
                  }
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Сум, хороо:
              </Form.Label>
              <Col sm={2}>
                <Dropdown
                  value={dataEvent.SUB_OFFICE_ID}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.SUB_OFFICE_ID = e.target.value;
                    setDataEvent({ ...value });
                  }}
                  options={
                    userData.user.ROLE_LEVEL_ID === 5
                      ? dropDown.subOffice
                          ?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(dataEvent.OFFICE_ID)
                          )
                          .filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.SUB_OFFICE_ID)
                          )
                      : dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) ===
                            parseInt(dataEvent.OFFICE_ID)
                        )
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Үйл ажиллагааны ангилал: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Dropdown
                  value={dataEvent.EVENT_TYPE_ID}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_TYPE_ID = e.target.value;
                    if (
                      dropDown.eventSubType?.filter(
                        (a) =>
                          parseInt(a.EVENT_TYPE_ID) === parseInt(e.target.value)
                      ).length < 0
                    ) {
                      value.EVENT_SUB_TYPE_ID = null;
                    }
                    setDataEvent({ ...value });
                  }}
                  options={dropDown.eventType}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Төрөл:
              </Form.Label>
              <Col sm={4}>
                <Dropdown
                  value={dataEvent.EVENT_SUB_TYPE_ID}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_SUB_TYPE_ID = e.target.value;
                    setDataEvent({ ...value });
                  }}
                  options={dropDown.eventSubType?.filter(
                    (a) =>
                      parseInt(a.EVENT_TYPE_ID) ===
                      parseInt(dataEvent.EVENT_TYPE_ID)
                  )}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Үйл ажиллагааны огноо:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="date"
                  size="sm"
                  value={DateFormat(dataEvent.EVENT_DATE)}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_DATE = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Оролцогчийн тоо:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataEvent.EVENT_PARTICIPANT}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_PARTICIPANT = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Үйл ажиллагааны нэр: <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataEvent.EVENT_NAME}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_NAME = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Сэдэв:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataEvent.EVENT_TOPIC}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_TOPIC = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Үйл ажиллагааны зардлын дүн /мян.төг/:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataEvent.EVENT_COST}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_COST = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Үйл ажиллагааны орлогын дүн /мян.төг/:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="number"
                  size="sm"
                  value={dataEvent.EVENT_INCOME}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_INCOME = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column sm={2} className="col-form-label-sm">
                Зохион байгуулагч:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataEvent.EVENT_ORGANIZED}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_ORGANIZED = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
              <Form.Label column sm={2} className="col-form-label-sm">
                Тайлбар:
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={dataEvent.EVENT_DESCRIPTION}
                  onChange={(e) => {
                    let value = dataEvent;
                    value.EVENT_DESCRIPTION = e.target.value;
                    setDataEvent({ ...value });
                  }}
                />
              </Col>
            </Form.Group>
            {dataEvent.ID && (
              <>
                <Form.Group className="mb-3 row">
                  <Form.Label column sm={2} className="col-form-label-sm">
                    Зураг:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="file"
                      size="sm"
                      accept="image/*"
                      onChange={(e) => {
                        let value = dataEventFile;
                        value.EVENT_FILE = e.target.files[0];
                        setDataEventFile({ ...value });
                      }}
                    />
                  </Col>
                  <Col sm={2}>
                    <Button
                      variant="info"
                      type="button"
                      onClick={() => saveEventFile()}
                      size="sm"
                      className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                    >
                      <FiUpload className="me-2" />
                      Хуулах
                    </Button>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <div
                    style={{
                      height: 180,
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflowX: "scroll",
                      overflowY: "hidden",
                    }}
                  >
                    {dataEventFileList.map((row, i) => (
                      <>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            margin: "8px 8px",
                            boxShadow: "2px 2px 4px #bbb",
                            borderTopRightRadius: "4px",
                            width: "120px",
                            height: "120px",
                            verticalAlign: "bottom",
                            backgroundPosition: "top left",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundImage: `url(${
                              process.env.REACT_APP_BASE_URL +
                              "/" +
                              row.FILE_PATH
                            })`,
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              border: "1px solid red",
                              borderRadius: "50%",
                              padding: "2px 4px",
                              cursor: "pointer",
                            }}
                            onClick={() => removeEventFile(row)}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </Form.Group>
              </>
            )}
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => saveEvent("save")}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiSave className="me-2" />
                Хадгалах
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={handleCloseModal}
                size="sm"
                className="me-2 d-flex justify-content-center align-items-center text-white px-2"
              >
                <FiLogOut className="me-2" />
                Хаах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        show={showConfirm}
        close={handleCloseConfirm}
        remove={() => removeEvent()}
      />
      {loading && <Loader />}
    </div>
  );
}
