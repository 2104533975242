var moment = require("moment");
export default function DateFormat(value, intent) {
  let format = "";
  switch (intent) {
    case "db":
      format = "DD-MMM-YYYY";
      break;
    case "time":
      format = "YYYY-MM-DD HH:mm:ss";
      break;
    default:
      format = "YYYY-MM-DD";
      break;
  }

  return value === null || value == undefined || value === "Invalid date"
    ? null
    : moment(value).format(format);
}
