import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FiCheck, FiX } from "react-icons/fi";

export default function RemoveConfirm(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Form>
            <div className="text-center fs-4 mb-4">
              Та уг мэдээллийг устгах уу?
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="success"
                type="button"
                onClick={() => props.remove()}
                size="sm"
                className="me-2 text-white"
              >
                <FiCheck className="me-2" />
                Тийм
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={props.close}
                size="sm"
                className="me-2 text-white"
              >
                <FiX className="me-2" />
                Үгүй
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
