import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FiEdit, FiTrash } from "react-icons/fi";
import DateFormat from "../../helper/DateFormat";

export default function TableReact(props) {
  const { header, data, isAction } = props;
  const getData = (head, val) => {
    if (head.type === "boolean") {
      return val ? "Тийм" : "Үгүй";
    } else if (head.type === "date") {
      return DateFormat(val);
    } else {
      return val;
    }
  };
  let num = 0;
  return (
    <Table responsive striped hover className=" elder-table-rounded">
      <thead>
        <tr className="table-primary">
          <th
            style={{ width: "30px", fontWeight: 700 }}
            className="text-center"
          >
            №
          </th>
          {header.map((head, index) => {
            return (
              <th key={"td" + index} style={{ fontWeight: 700 }}>
                {head.name}
              </th>
            );
          })}
          {props.isAction && (
            <th width={30} className="text-center" style={{ fontWeight: 700 }}>
              Үйлдэл
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {/* {rowNo ? <td className="text-center">{num}</td> : null} */}
        {data.map((item, index) => {
          num++;
          //console.log(item, "item");
          return (
            <tr key={"tr" + index}>
              <td className="text-center">{num}</td>
              {header.map((head, headIndex) => {
                return (
                  <td key={"td" + headIndex} width={head.width}>
                    {getData(head, item[head.dName])}
                  </td>
                );
              })}
              {props.isAction && (
                <td width={30} className="text-center">
                  <ButtonGroup>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => props.editRow(item)}
                    >
                      <FiEdit />
                    </Button>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => props.removeRow(item.ID)}
                    >
                      <FiTrash />
                    </Button>
                  </ButtonGroup>
                </td>
              )}
            </tr>
          );
        })}
        {data.length === 0 && (
          <tr>
            <td colSpan={header.length + 2} className="text-center h-24">
              Өгөгдөл хоосон{" "}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
