import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

export default function ChartBar(props) {
  //console.log(props.barData.label, "props.barData.label");
  const data = {
    labels: props.barData.label,
    datasets: [
      {
        label: "",
        data: props.barData.value,
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],
    // options: {
    //   legend: {
    //     display: false,
    //   },
    //   tooltips: {
    //     enabled: false,
    //   },
    // },
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <h4 className="card-title" style={{ textTransform: "none" }}>
            {props.barData.title}
          </h4>
        </div>
        <div
          className="d-flex justify-content-center w-100"
          style={{ maxHeight: "450px", height: "400px" }}
        >
          <Bar
            data={data}
            className="text-center"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
