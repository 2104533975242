import React from "react";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Button } from "react-bootstrap";
import { FiPrinter } from "react-icons/fi";
import ElderPrint from "./print/ElderPrint";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        {this.props.data.ID ? (
          <ElderPrint id={this.props.data.ID} />
        ) : (
          <h1>Төрөл таарсангүй</h1>
        )}
      </div>
    );
  }
}

class PrintClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      humor: "happy",
    };
  }
  render() {
    return (
      <div>
        <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <Button
                variant="info"
                size="sm"
                className="d-flex justify-content-center align-items-center text-white px-2"
                onClick={() => handlePrint()}
              >
                <FiPrinter className="me-2" />
                Хэвлэх
              </Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>

        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={(el) => (this.componentRef = el)}
            data={this.props.data}
          />
        </div>
      </div>
    );
  }
}

export default PrintClass;
