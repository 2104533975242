import React, { useState, useEffect } from "react";

const Pagination = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  currentPage,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  // const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(1);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  // Onclick handlers for the butons
  const onNextPage = () => pageChangeHandler(currentPage + 1);
  const onPrevPage = () => pageChangeHandler(currentPage - 1);
  const onFirstPage = () => pageChangeHandler(0);
  const onLastPage = () => pageChangeHandler(noOfPages - 1);
  const onPageSelect = (pageNo) => pageChangeHandler(pageNo);

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage + 1) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 0) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    // pageChangeHandler(skipFactor);
    // pageChangeHandler(currentPage)
    setPageFirstRecord(skipFactor + 1);
  }, [currentPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <>
      {noOfPages > 1 ? (
        <ul className="pagination">
          <li
            className="page-item"
            style={{ cursor: "pointer" }}
            onClick={onFirstPage}
          >
            <span className="page-link">{"<<"}</span>
          </li>
          <li
            className="page-item"
            style={{ cursor: "pointer" }}
            onClick={canGoBack ? onPrevPage : null}
          >
            <span className="page-link">{"<"}</span>
          </li>
          <li
            className="page-item"
            style={{ cursor: "pointer" }}
            onClick={canGoNext ? onNextPage : null}
          >
            <span className="page-link">{">"}</span>
          </li>
          <li
            className="page-item"
            style={{ cursor: "pointer" }}
            onClick={onLastPage}
          >
            <span className="page-link">{">>"}</span>
          </li>
          <li className="page-item" style={{ cursor: "pointer" }}>
            <span className="page-link">
              Хуудас{" "}
              <strong>
                {currentPage + 1} / {noOfPages}
              </strong>{" "}
            </span>
          </li>
          {/*<li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <span className="page-link">{"<"}</span>
            </li>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <span className="page-link">{">"}</span>
            </li>
            <li
              className="page-item"
              style={{ cursor: "pointer" }}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <span className="page-link">{">>"}</span>
            </li>
            <li className="page-item" style={{ cursor: "pointer" }}>
              <span className="page-link">
                Хуудас{" "}
                <strong>
                  {pageIndex + 1} / {pageOptions.length}
                </strong>{" "}
              </span>
            </li> */}
        </ul>
      ) : null}
    </>
  );
};

export default Pagination;
