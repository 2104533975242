import React from "react";
import {
  FiCalendar,
  FiCheckSquare,
  FiHome,
  FiUser,
  FiAward,
  FiPackage,
} from "react-icons/fi";

export default function Card(props) {
  let icon;
  switch (props.icon) {
    case "user":
      icon = <FiUser className="me-2" style={{ color: "#5B5EA6" }} />;
      break;

    case "event":
      icon = <FiCalendar className="me-2" style={{ color: "#FF6F61" }} />;
      break;
    case "friendly":
      icon = <FiCheckSquare className="me-2" style={{ color: "#6B5B95" }} />;
      break;
    case "org":
      icon = <FiHome className="me-2" style={{ color: "#88B04B" }} />;
      break;
    case "award":
      icon = <FiAward className="me-2" style={{ color: "#40B4DB" }} />;
      break;
    case "fund":
      icon = <FiPackage className="me-2" style={{ color: "#40B4DB" }} />;
      break;
    default:
      break;
  }
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <div
            className="card-title"
            style={{
              textTransform: "none",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: props.textSize,
            }}
          >
            {props.title}
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center fs-1 fw-bold">
          {icon}
          <div style={{ color: "#34568B" }}>{props.value}</div>
        </div>
      </div>
    </div>
  );
}
