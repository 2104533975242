import React from "react";

const axios = require("axios");
class ElderPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataElder: {},
      dataAward: [],
      dataDependence: [],
      dataSupply: [],
      dataMovement: [],
    };
  }

  async componentDidMount() {
    try {
      const result = (await axios.get("/regElderId?elderId=" + this.props?.id))
        .data;
      this.setState({
        dataElder: result.Elder,
        dataAward: result.Award,
        dataDependence: result.Dependence,
        dataSupply: result.Supply,
      });
    } catch (error) {}
  }

  render() {
    return <div className="printContent">{JSON.stringify(this.state)}</div>;
  }
}

export default ElderPrint;
