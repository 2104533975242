import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserContext from "../helper/UserContext";
import axios from "axios";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useToken } from "../components/hook/CustomHooks";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
export default function Main() {
  useToken();
  const [cookies, setCookie, removeCookie] = useCookies([""]);
  const [user, setUser] = useState(null);
  const [isAuth, setAuth] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 500);
  }, []);
  let navigate = useNavigate();
  const logout = () => {
    removeCookie("auth-token", "/");
    navigate("/login");
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    navigate(0); //logout deer login refresh hj cache tsewerlej bga
  };
  const getUser = async () => {
    try {
      const result = (await axios.get("/regUserDetail")).data;
      setUser(result);
      const resultPermission = (await axios.get("/regUserPermission")).data;
      setUser((user) => ({
        user: { ...user[0] },
        permission: resultPermission,
      }));
      if (cookies == "") {
        setAuth(false);
      } else {
        setAuth(true);
      }
    } catch (error) {
      logout();
    }
  };
  return (
    <UserContext.Provider value={user}>
      {isAuth ? (
        <div className="container-scroller" style={{ height: "100vh" }}>
          <Header logout={logout} />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div
                className="content-wrapper"
                style={{
                  height: "calc(100vh - 20px)",
                  overflowY: "scroll",
                }}
              >
                <Outlet />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <div>Loading ...</div>
      )}
    </UserContext.Provider>
  );
}
