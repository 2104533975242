import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";

import Dropdown from "../components/custom/Dropdown";
import TableDefault from "../components/custom/TableDefault";
import ConfirmModal from "../components/custom/RemoveConfirm";
import {
  FiPlusSquare,
  FiTrash,
  FiEdit,
  FiLogOut,
  FiSave,
} from "react-icons/fi";
import DateFormat from "../helper/DateFormat";
import UserContext from "../helper/UserContext";
import { ExportToExcel } from "../helper/ExportToExcel";
import Loader from "../components/custom/Loader";
import { checkNullNumber, checkNullString } from "../helper/isVal";

export default function Organization(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [structuralList, setStructuralList] = useState([]);
  const [nonStructuralList, setNonStructuralList] = useState([]);

  const [filterStructuralList, setFilterStructuralList] = useState({
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
  });

  const [filterNonStructuralList, setFilterNonStructuralList] = useState({
    OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 3 ||
      userData.user.ROLE_LEVEL_ID === 4 ||
      userData.user.ROLE_LEVEL_ID === 5 ||
      userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.OFFICE_ID
        : 999,
    SUB_OFFICE_ID:
      userData.user.ROLE_LEVEL_ID === 5 || userData.user.ROLE_LEVEL_ID === 6
        ? userData.user.SUB_OFFICE_ID
        : 999,
  });

  const [filteredStructuralList, setFilteredStructuralList] = useState([]);
  const [filteredNonStructuralList, setFilteredNonStructuralList] = useState(
    []
  );
  const filterDataStructural = () => {
    setFilteredStructuralList(structuralList);

    if (filterStructuralList.OFFICE_ID !== 999) {
      setFilteredStructuralList((a) =>
        a.filter((b) => b.OFFICE_ID === filterStructuralList.OFFICE_ID)
      );
    }
    if (filterStructuralList.SUB_OFFICE_ID !== 999) {
      setFilteredStructuralList((a) =>
        a.filter((b) => b.SUB_OFFICE_ID === filterStructuralList.SUB_OFFICE_ID)
      );
    }
  };

  const filterDataNonStructural = () => {
    setFilteredNonStructuralList(nonStructuralList);

    if (filterNonStructuralList.OFFICE_ID !== 999) {
      setFilteredNonStructuralList((a) =>
        a.filter((b) => b.OFFICE_ID === filterNonStructuralList.OFFICE_ID)
      );
    }
    if (filterNonStructuralList.SUB_OFFICE_ID !== 999) {
      setFilteredNonStructuralList((a) =>
        a.filter(
          (b) => b.SUB_OFFICE_ID === filterNonStructuralList.SUB_OFFICE_ID
        )
      );
    }
  };

  const [dataStructural, setDataStructural] = useState({
    ID: null,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    ORG_TYPE_ID: 1,
    ORG_NAME: "",
    ORG_ADDRESS: "",
    ROOM_COUNT: null,
    FIELD_SIZE: null,
    CONFERENCE_SIZE: null,
    COMPUTER_COUNT: null,
    DESK_COUNT: null,
    CHAIR_COUNT: null,
    BOARD_COUNT: null,
    OTHER_DETAIL: "",
    HEAD_NAME: "",
    HEAD_PHONE: "",
    MANAGER_NAME: "",
    MANAGER_PHONE: "",
    ORG_WORKER: null,
    HAS_ELDER_FUND: null,
    ORG_TRUST_DATE: null,
    ORG_TRUST_DAY: false,
    ORG_TRUST_LESSON: false,
    ORG_TRUST_HOME: false,
    ORG_TRUST_CONSULTING: false,
  });

  const [showModalStructural, setShowModalStructural] = useState(false);
  const handleCloseModalStructural = () => setShowModalStructural(false);
  const handleShowModalStructural = () => setShowModalStructural(true);

  const [dataNonStructural, setDataNonStructural] = useState({
    ID: null,
    OFFICE_ID: 999,
    SUB_OFFICE_ID: 999,
    ORG_TYPE_ID: 2,
    ORG_SUB_TYPE_ID: 999,
    HEAD_NAME: "",
    HEAD_PHONE: "",
    ORG_WORKER: null,
    ORG_NAME: "",
    ORG_ADDRESS: "",
    ORG_PHONE: "",
    HAS_ELDER_FUND: null,
    ELDER_COUNT: null,
    ORG_TRUST_DATE: null,
    ORG_TRUST_DAY: false,
    ORG_TRUST_LESSON: false,
    ORG_TRUST_HOME: false,
    ORG_TRUST_CONSULTING: false,
  });

  const [showModalNonStructural, setShowModalNonStructural] = useState(false);
  const handleCloseModalNonStructural = () => setShowModalNonStructural(false);
  const handleShowModalNonStructural = () => setShowModalNonStructural(true);

  const columnsStructural = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },
    {
      Header: "Аймаг, дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Байгууллагын нэр",
      accessor: "ORG_NAME",
    },
    {
      Header: "Үйл ажиллагаа явуулдаг хаяг",
      accessor: "ORG_ADDRESS",
    },
    {
      Header: "Даргын нэр",
      accessor: "HEAD_NAME",
    },
    {
      Header: "Даргын утас",
      accessor: "HEAD_PHONE",
    },
    {
      Header: "Ахмадын сантай эсэх",
      accessor: (row, index) => {
        return row.HAS_ELDER_FUND === 1 ? "Тийм" : "Үгүй";
      },
    },
    {
      Header: "Авсан огноо",
      accessor: (row, index) => {
        return DateFormat(row.ORG_TRUST_DATE);
      },
    },
    {
      Header: "Үйлдэл",
      accessor: (row) => (
        <div className="text-center">
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => editStructural(row)}
            >
              <FiEdit />
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => removeRow(row.ID)}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const columnsNonStructural = [
    {
      Header: "№",
      accessor: (row, index) => {
        return <div className="text-center"> {index + 1}</div>;
      },
      size: 30,
      align: "center",
    },

    {
      Header: "Аймаг, дүүрэг",
      accessor: "OFFICE_NAME",
    },
    {
      Header: "Сум, хороо",
      accessor: "SUB_OFFICE_NAME",
    },
    {
      Header: "Байгууллагын нэр",
      accessor: "ORG_NAME",
    },
    {
      Header: "Хариуцлагын хэлбэр",
      accessor: "ORG_SUB_TYPE_NAME",
    },
    {
      Header: "Даргын нэр",
      accessor: "HEAD_NAME",
    },
    {
      Header: "Даргын утас",
      accessor: "HEAD_PHONE",
    },
    {
      Header: "Үйл ажиллагаа явуулдаг байршил",
      accessor: "ORG_ADDRESS",
    },
    {
      Header: "Холбоо барих утас",
      accessor: "ORG_PHONE",
    },
    {
      Header: "Харьяалалтай ахмадын тоо",
      accessor: "ELDER_COUNT",
    },
    {
      Header: "Ахмадын сантай эсэх",
      accessor: (row, index) => {
        return row.HAS_ELDER_FUND === 1 ? "Тийм" : "Үгүй";
      },
    },
    {
      Header: "Авсан огноо",
      accessor: (row, index) => {
        return DateFormat(row.ORG_TRUST_DATE);
      },
    },
    {
      Header: "Үйлдэл", // Header: "Аудитын үе шат",
      accessor: (row) => (
        <div className="text-center">
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => editNonStructural(row)}
            >
              <FiEdit />
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => removeRow(row.ID)}
            >
              <FiTrash />
            </Button>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const [dropDown, setDropDown] = useState({
    office: [],
    subOffice: [],
    orgSubType: [],
  });

  //fetchData
  useEffect(() => {
    getLibrary();
    fetchData();
  }, []);
  const getLibrary = async () => {
    try {
      const result1 = (await axios.get("/refOfficeWithHead")).data;
      const result2 = (await axios.get("/refSubOfficeWithHead")).data;
      const result3 = (await axios.get("/refOrgSubType")).data;

      setDropDown({
        office: result1,
        subOffice: result2,
        orgSubType: result3,
      });
    } catch (error) {}
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);
  const [rowDeletedID, setRowDeletedID] = useState();

  async function detailStructural(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              ORG_TYPE_ID: 1,
              ORG_NAME: "",
              ORG_ADDRESS: "",
              ROOM_COUNT: null,
              FIELD_SIZE: null,
              CONFERENCE_SIZE: null,
              COMPUTER_COUNT: null,
              DESK_COUNT: null,
              CHAIR_COUNT: null,
              BOARD_COUNT: null,
              OTHER_DETAIL: "",
              HEAD_NAME: "",
              HEAD_PHONE: "",
              MANAGER_NAME: "",
              MANAGER_PHONE: "",
              ORG_WORKER: null,
              HAS_ELDER_FUND: null,
              ORG_TRUST_DATE: null,
              ORG_TRUST_DAY: false,
              ORG_TRUST_LESSON: false,
              ORG_TRUST_HOME: false,
              ORG_TRUST_CONSULTING: false,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              ORG_TYPE_ID: row.ORG_TYPE_ID,
              ORG_NAME: row.ORG_NAME,
              ORG_ADDRESS: row.ORG_ADDRESS,
              ROOM_COUNT: row.ROOM_COUNT,
              FIELD_SIZE: row.FIELD_SIZE,
              CONFERENCE_SIZE: row.CONFERENCE_SIZE,
              COMPUTER_COUNT: row.COMPUTER_COUNT,
              DESK_COUNT: row.DESK_COUNT,
              CHAIR_COUNT: row.CHAIR_COUNT,
              BOARD_COUNT: row.BOARD_COUNT,
              OTHER_DETAIL: row.OTHER_DETAIL,
              HEAD_NAME: row.HEAD_NAME,
              HEAD_PHONE: row.HEAD_PHONE,
              MANAGER_NAME: row.MANAGER_NAME,
              MANAGER_PHONE: row.MANAGER_PHONE,
              ORG_WORKER: row.ORG_WORKER,
              HAS_ELDER_FUND: row.HAS_ELDER_FUND,
              ORG_TRUST_DATE: row.ORG_TRUST_DATE,
              ORG_TRUST_DAY: row.ORG_TRUST_DAY,
              ORG_TRUST_LESSON: row.ORG_TRUST_LESSON,
              ORG_TRUST_HOME: row.ORG_TRUST_HOME,
              ORG_TRUST_CONSULTING: row.ORG_TRUST_CONSULTING,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };

      setDataStructural(data);
      handleShowModalStructural();
    } catch (error) {}
  }

  const editStructural = (row) => {
    detailStructural(row);
  };
  function requiredStructural() {
    if (checkNullNumber(dataStructural.OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataStructural.SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullString(dataStructural.ORG_NAME)) {
      alert("Байгууллагын нэр оруулна уу");
    } else if (checkNullString(dataStructural.ORG_ADDRESS)) {
      alert("Үйл ажиллагаа явуулдаг хаяг, байршил оруулна уу");
    } else if (checkNullNumber(dataStructural.ROOM_COUNT)) {
      alert("Өрөөний тоо оруулна уу");
    } else if (checkNullNumber(dataStructural.FIELD_SIZE)) {
      alert("Нийт талбайн хэмжээ м.кв оруулна уу");
    } else if (checkNullString(dataStructural.HEAD_NAME)) {
      alert("Даргын нэр оруулна уу");
    } else if (checkNullString(dataStructural.HEAD_PHONE)) {
      alert("Даргын утас оруулна уу");
    } else if (checkNullNumber(dataStructural.HAS_ELDER_FUND)) {
      alert("Ахмадын сантай эсэх сонгоно уу");
    } else {
      return true;
    }
  }

  async function detailNonStructural(row) {
    try {
      let data =
        row === undefined
          ? {
              ID: null,
              OFFICE_ID: 999,
              SUB_OFFICE_ID: 999,
              ORG_TYPE_ID: 2,
              ORG_SUB_TYPE_ID: 999,
              HEAD_NAME: "",
              HEAD_PHONE: "",
              ORG_WORKER: null,
              ORG_NAME: "",
              ORG_ADDRESS: "",
              ORG_PHONE: "",
              HAS_ELDER_FUND: null,
              ELDER_COUNT: null,
              ORG_TRUST_DATE: null,
              ORG_TRUST_DAY: false,
              ORG_TRUST_LESSON: false,
              ORG_TRUST_HOME: false,
              ORG_TRUST_CONSULTING: false,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            }
          : {
              ID: row.ID,
              OFFICE_ID: row.OFFICE_ID,
              SUB_OFFICE_ID: row.SUB_OFFICE_ID,
              ORG_TYPE_ID: row.ORG_TYPE_ID,
              ORG_SUB_TYPE_ID: row.ORG_SUB_TYPE_ID,
              HEAD_NAME: row.HEAD_NAME,
              HEAD_PHONE: row.HEAD_PHONE,
              ORG_WORKER: row.ORG_WORKER,
              ORG_NAME: row.ORG_NAME,
              ORG_ADDRESS: row.ORG_ADDRESS,
              ORG_PHONE: row.ORG_PHONE,
              HAS_ELDER_FUND: row.HAS_ELDER_FUND,
              ELDER_COUNT: row.ELDER_COUNT,
              ORG_TRUST_DATE: row.ORG_TRUST_DATE,
              ORG_TRUST_DAY: row.ORG_TRUST_DAY,
              ORG_TRUST_LESSON: row.ORG_TRUST_LESSON,
              ORG_TRUST_HOME: row.ORG_TRUST_HOME,
              ORG_TRUST_CONSULTING: row.ORG_TRUST_CONSULTING,
              IS_ACTIVE: 1,
              CREATED_BY: userData.user.USER_ID,
            };

      setDataNonStructural(data);
      handleShowModalNonStructural();
    } catch (error) {}
  }

  const editNonStructural = (row) => {
    detailNonStructural(row);
  };
  function requiredNonStructural() {
    if (checkNullNumber(dataNonStructural.OFFICE_ID)) {
      alert("Аймаг, дүүрэг сонгоно уу");
    } else if (checkNullNumber(dataNonStructural.SUB_OFFICE_ID)) {
      alert("Сум, хороо сонгоно уу");
    } else if (checkNullString(dataNonStructural.ORG_NAME)) {
      alert("Байгууллагын нэр оруулна уу");
    } else if (checkNullNumber(dataNonStructural.ORG_SUB_TYPE_ID)) {
      alert("Хариуцлагын хэлбэр сонгоно уу");
    } else if (checkNullString(dataNonStructural.HEAD_NAME)) {
      alert("Даргын нэр оруулна уу");
    } else if (checkNullString(dataNonStructural.HEAD_PHONE)) {
      alert("Даргын утас оруулна уу");
    } else if (checkNullString(dataNonStructural.ORG_ADDRESS)) {
      alert("Үйл ажиллагаа явуулдаг хаяг, байршил оруулна уу");
    } else if (checkNullNumber(dataNonStructural.ORG_PHONE)) {
      alert("Холбоо барих утас оруулна уу");
    } else if (checkNullNumber(dataNonStructural.ELDER_COUNT)) {
      alert("Харьяалалтай ахмадын тоо оруулна уу");
    } else {
      return true;
    }
  }

  async function saveStructural(action) {
    try {
      let data = {};
      if (action === "saveStructural") {
        data = {
          ID: dataStructural.ID,
          OFFICE_ID: dataStructural.OFFICE_ID,
          SUB_OFFICE_ID: dataStructural.SUB_OFFICE_ID,
          ORG_TYPE_ID: dataStructural.ORG_TYPE_ID,
          ORG_SUB_TYPE_ID: null,
          ORG_NAME: dataStructural.ORG_NAME,
          ORG_ADDRESS: dataStructural.ORG_ADDRESS,
          ROOM_COUNT: dataStructural.ROOM_COUNT,
          FIELD_SIZE: dataStructural.FIELD_SIZE,
          CONFERENCE_SIZE: dataStructural.CONFERENCE_SIZE,
          COMPUTER_COUNT: dataStructural.COMPUTER_COUNT,
          DESK_COUNT: dataStructural.DESK_COUNT,
          CHAIR_COUNT: dataStructural.CHAIR_COUNT,
          BOARD_COUNT: dataStructural.BOARD_COUNT,
          OTHER_DETAIL: dataStructural.OTHER_DETAIL,
          ELDER_COUNT: null,
          HEAD_NAME: dataStructural.HEAD_NAME,
          HEAD_PHONE: dataStructural.HEAD_PHONE,
          MANAGER_NAME: dataStructural.MANAGER_NAME,
          MANAGER_PHONE: dataStructural.MANAGER_PHONE,
          ORG_WORKER: dataStructural.ORG_WORKER,
          ORG_PHONE: null, //dataStructural.ORG_PHONE,
          HAS_ELDER_FUND: dataStructural.HAS_ELDER_FUND,
          ORG_TRUST_DATE: DateFormat(dataStructural.ORG_TRUST_DATE, "db"),
          ORG_TRUST_DAY: JSON.parse(dataStructural.ORG_TRUST_DAY) ? 1 : 0,
          ORG_TRUST_LESSON: JSON.parse(dataStructural.ORG_TRUST_LESSON) ? 1 : 0,
          ORG_TRUST_HOME: JSON.parse(dataStructural.ORG_TRUST_HOME) ? 1 : 0,
          ORG_TRUST_CONSULTING: JSON.parse(dataStructural.ORG_TRUST_CONSULTING)
            ? 1
            : 0,
          IS_ACTIVE: 1,
          CREATED_BY: userData.user.USER_ID,
        };
      } else {
        data = {
          ID: dataNonStructural.ID,
          OFFICE_ID: dataNonStructural.OFFICE_ID,
          SUB_OFFICE_ID: dataNonStructural.SUB_OFFICE_ID,
          ORG_TYPE_ID: dataNonStructural.ORG_TYPE_ID,
          ORG_SUB_TYPE_ID: dataNonStructural.ORG_SUB_TYPE_ID,
          ORG_NAME: dataNonStructural.ORG_NAME,
          ORG_ADDRESS: dataNonStructural.ORG_ADDRESS,
          ROOM_COUNT: null,
          FIELD_SIZE: null,
          CONFERENCE_SIZE: null,
          COMPUTER_COUNT: null,
          DESK_COUNT: null,
          CHAIR_COUNT: null,
          BOARD_COUNT: null,
          OTHER_DETAIL: null,
          ELDER_COUNT: dataNonStructural.ELDER_COUNT,
          HEAD_NAME: dataNonStructural.HEAD_NAME,
          HEAD_PHONE: dataNonStructural.HEAD_PHONE,
          MANAGER_NAME: null,
          MANAGER_PHONE: null,
          ORG_WORKER: dataNonStructural.ORG_WORKER,
          ORG_PHONE: dataNonStructural.ORG_PHONE,
          HAS_ELDER_FUND: dataNonStructural.HAS_ELDER_FUND,
          ORG_TRUST_DATE:
            dataNonStructural.ORG_TRUST_DATE !== null
              ? DateFormat(dataNonStructural.ORG_TRUST_DATE, "db")
              : null,
          ORG_TRUST_DAY: JSON.parse(dataNonStructural.ORG_TRUST_DAY) ? 1 : 0,
          ORG_TRUST_LESSON: JSON.parse(dataNonStructural.ORG_TRUST_LESSON)
            ? 1
            : 0,
          ORG_TRUST_HOME: JSON.parse(dataNonStructural.ORG_TRUST_HOME) ? 1 : 0,
          ORG_TRUST_CONSULTING: JSON.parse(
            dataNonStructural.ORG_TRUST_CONSULTING
          )
            ? 1
            : 0,
          IS_ACTIVE: 1,
          CREATED_BY: userData.user.USER_ID,
        };
      }
      if (action === "saveStructural") {
        if (requiredStructural()) {
          setLoading(true);
          let result = {};
          result = await axios.post("/regOrganizationIU", data);
          alert(result.data.message);
          handleCloseModalStructural();
          fetchData();
          setLoading(false);
        }
      } else {
        console.log(dataNonStructural, "State");
        console.log(data, "data");
        if (requiredNonStructural()) {
          setLoading(true);
          let result = {};
          result = await axios.post("/regOrganizationIU", data);
          alert(result.data.message);
          handleCloseModalNonStructural();
          fetchData();
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }
  async function removeRow(ID) {
    setRowDeletedID(ID);
    handleShowConfirm();
  }
  async function removeStructural() {
    try {
      let data = {
        ID: rowDeletedID,
        deletedBy: userData.user.USER_ID,
      };
      setLoading(true);
      let result = {};
      result = await axios.post("/regOrganizationRemove", data);
      alert(result.data.message);
      handleCloseConfirm();
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const result = (
        await axios.post("/regOrganizationList", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setStructuralList(result.filter((e) => e.ORG_TYPE_ID === 1));
      setFilteredStructuralList(result.filter((e) => e.ORG_TYPE_ID === 1));
      setNonStructuralList(result.filter((e) => e.ORG_TYPE_ID === 2));
      setFilteredNonStructuralList(result.filter((e) => e.ORG_TYPE_ID === 2));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <Card>
          <Card.Body>
            <Card.Title className="text-uppercase fs-6">
              Ахмадын байгууллагын бүртгэл
            </Card.Title>
            <Tabs defaultActiveKey="Org" id="justify-tab-example">
              <Tab eventKey="Org" title="Нутаг дэвсгэрийн АБ">
                <div className="d-flex">
                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 3 ||
                      userData.user.ROLE_LEVEL_ID === 4 ||
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterStructuralList.OFFICE_ID}
                      onChange={(e) => {
                        let value = filterStructuralList;
                        value.OFFICE_ID = parseInt(e.target.value);

                        if (
                          dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                          )
                        ) {
                          value.SUB_OFFICE_ID = 999;
                        }
                        setFilterStructuralList({ ...value });
                        filterDataStructural();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 1 ||
                        userData.user.ROLE_LEVEL_ID === 99
                          ? dropDown.office
                          : userData.user.ROLE_LEVEL_ID === 2
                          ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                          : dropDown.office.filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.OFFICE_ID)
                            )
                      }
                    />
                  </div>
                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterStructuralList.SUB_OFFICE_ID}
                      onChange={(e) => {
                        let value = filterStructuralList;
                        value.SUB_OFFICE_ID = parseInt(e.target.value);
                        setFilterStructuralList({ ...value });
                        filterDataStructural();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 5
                          ? dropDown.subOffice
                              ?.filter(
                                (a) =>
                                  parseInt(a.OFFICE_ID) ===
                                  parseInt(filterStructuralList.OFFICE_ID)
                              )
                              .filter(
                                (a) =>
                                  parseInt(a.ID) ===
                                  parseInt(userData.user.SUB_OFFICE_ID)
                              )
                          : dropDown.subOffice?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(filterStructuralList.OFFICE_ID)
                            )
                      }
                    />
                  </div>
                  <Button
                    variant="light"
                    onClick={() => detailStructural()}
                    size="sm"
                    className="me-2 d-flex justify-content-center align-items-center px-2"
                  >
                    <FiPlusSquare className="me-2" />
                    Нэмэх
                  </Button>
                  <ExportToExcel
                    //colData={rows}
                    fileName={
                      "Нутаг дэвсгэрийн АБ " + DateFormat(Date.now(), "time")
                    }
                    //colHeader={excelHeader}
                  />
                </div>
                <div className="table-responsive p-2">
                  <TableDefault
                    columns={columnsStructural}
                    data={filteredStructuralList}
                  />
                </div>
              </Tab>
              <Tab eventKey="AAN" title="Байгууллага, аж ахуйн дэргэдэх АБ">
                <div className="d-flex">
                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 3 ||
                      userData.user.ROLE_LEVEL_ID === 4 ||
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterNonStructuralList.OFFICE_ID}
                      onChange={(e) => {
                        let value = filterNonStructuralList;
                        value.OFFICE_ID = parseInt(e.target.value);

                        if (
                          dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                          )
                        ) {
                          value.SUB_OFFICE_ID = 999;
                        }
                        setFilterNonStructuralList({ ...value });
                        filterDataNonStructural();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 1 ||
                        userData.user.ROLE_LEVEL_ID === 99
                          ? dropDown.office
                          : userData.user.ROLE_LEVEL_ID === 2
                          ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                          : dropDown.office.filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.OFFICE_ID)
                            )
                      }
                    />
                  </div>
                  <div
                    className={`me-2 ${
                      userData.user.ROLE_LEVEL_ID === 5 ||
                      userData.user.ROLE_LEVEL_ID === 6
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <Dropdown
                      value={filterStructuralList.SUB_OFFICE_ID}
                      onChange={(e) => {
                        let value = filterNonStructuralList;
                        value.SUB_OFFICE_ID = parseInt(e.target.value);
                        setFilterNonStructuralList({ ...value });
                        filterDataNonStructural();
                      }}
                      options={
                        userData.user.ROLE_LEVEL_ID === 5
                          ? dropDown.subOffice
                              ?.filter(
                                (a) =>
                                  parseInt(a.OFFICE_ID) ===
                                  parseInt(filterNonStructuralList.OFFICE_ID)
                              )
                              .filter(
                                (a) =>
                                  parseInt(a.ID) ===
                                  parseInt(userData.user.SUB_OFFICE_ID)
                              )
                          : dropDown.subOffice?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(filterNonStructuralList.OFFICE_ID)
                            )
                      }
                    />
                  </div>
                  <Button
                    variant="light"
                    onClick={() => detailNonStructural()}
                    size="sm"
                    className="me-2 d-flex justify-content-center align-items-center px-2"
                  >
                    <FiPlusSquare className="me-2" />
                    Нэмэх
                  </Button>
                  <ExportToExcel
                    //colData={rows}
                    fileName={
                      "Байгууллага, аж ахуйн дэргэдэх АБ" +
                      DateFormat(Date.now(), "time")
                    }
                    //colHeader={excelHeader}
                  />
                </div>
                <div className="table-responsive p-2">
                  <TableDefault
                    columns={columnsNonStructural}
                    data={filteredNonStructuralList}
                  />
                </div>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
        <Modal
          show={showModalStructural}
          onHide={handleCloseModalStructural}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Нутаг дэвсгэрийн АБ бүртгэл</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Аймаг, дүүрэг: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataStructural.OFFICE_ID}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.OFFICE_ID = e.target.value;
                      if (
                        dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.SUB_OFFICE_ID = null;
                      }
                      setDataStructural({ ...value });
                    }}
                    //options={dropDown.office}
                    options={
                      userData.user.ROLE_LEVEL_ID === 1 ||
                      userData.user.ROLE_LEVEL_ID === 99
                        ? dropDown.office
                        : userData.user.ROLE_LEVEL_ID === 2
                        ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                        : dropDown.office.filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.OFFICE_ID)
                          )
                    }
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Сум, хороо: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataStructural.SUB_OFFICE_ID}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.SUB_OFFICE_ID = e.target.value;
                      setDataStructural({ ...value });
                    }}
                    options={
                      userData.user.ROLE_LEVEL_ID === 5
                        ? dropDown.subOffice
                            ?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(dataStructural.OFFICE_ID)
                            )
                            .filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.SUB_OFFICE_ID)
                            )
                        : dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(dataStructural.OFFICE_ID)
                          )
                    }
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Ахмадын сантай эсэх: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Select
                    size="sm"
                    value={dataStructural.HAS_ELDER_FUND}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.HAS_ELDER_FUND = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  >
                    <option value={999}>Сонгоно уу</option>
                    <option value={1}>Тийм</option>
                    <option value={0}>Үгүй</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Байгууллагын нэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    size="sm"
                    value={dataStructural.ORG_NAME}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_NAME = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Үйл ажиллагаа явуулдаг хаяг, байршил:{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    size="sm"
                    value={dataStructural.ORG_ADDRESS}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_ADDRESS = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <h4>Өрөө, тасалгаа</h4>
              <hr />
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Өрөөний тоо: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.ROOM_COUNT}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ROOM_COUNT = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Нийт талбайн хэмжээ м.кв:{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.FIELD_SIZE}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.FIELD_SIZE = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Хурал, сургалтын талбайн хэмжээ м.кв
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.CONFERENCE_SIZE}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.CONFERENCE_SIZE = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <h4>Техник хэрэгсэл</h4>
              <hr />
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm mb-3">
                  Компьютерийн тоо
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.COMPUTER_COUNT}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.COMPUTER_COUNT = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Ширээний тоо
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.DESK_COUNT}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.DESK_COUNT = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Сандлын тоо
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.CHAIR_COUNT}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.CHAIR_COUNT = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm mb-3">
                  Самбарын тоо
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.BOARD_COUNT}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.BOARD_COUNT = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Бусад техник
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataStructural.OTHER_DETAIL}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.OTHER_DETAIL = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <h4>Хүний нөөц</h4>
              <hr />
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Даргын нэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataStructural.HEAD_NAME}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.HEAD_NAME = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Даргын утас: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataStructural.HEAD_PHONE}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.HEAD_PHONE = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Зохион байгуулагчийн нэр:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataStructural.MANAGER_NAME}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.MANAGER_NAME = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Зохион байгуулагчийн утас:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataStructural.MANAGER_PHONE}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.MANAGER_PHONE = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Бусад ажилтнуудын тоо:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataStructural.ORG_WORKER}
                    className="float-right"
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_WORKER = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <h4>Магадлан итгэмжлэл авсан байгууллага</h4>
              <hr />
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm mb-3">
                  Авсан огноо:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="date"
                    size="sm"
                    value={DateFormat(dataStructural.ORG_TRUST_DATE)}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_TRUST_DATE = e.target.value;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_DAY`}
                    label={`Өдрийн үйлчилгээ`}
                    checked={JSON.parse(dataStructural.ORG_TRUST_DAY)}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_TRUST_DAY = e.target.checked;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_LESSON`}
                    label={`Сургалт`}
                    checked={JSON.parse(dataStructural.ORG_TRUST_LESSON)}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_TRUST_LESSON = e.target.checked;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_HOME`}
                    label={`Гэрийн үйлчилгээ`}
                    checked={JSON.parse(dataStructural.ORG_TRUST_HOME)}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_TRUST_HOME = e.target.checked;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_CONSULTING`}
                    label={`Ахмад мэргэжилтний зөвлөх үйлчилгээ`}
                    checked={JSON.parse(dataStructural.ORG_TRUST_CONSULTING)}
                    onChange={(e) => {
                      let value = dataStructural;
                      value.ORG_TRUST_CONSULTING = e.target.checked;
                      setDataStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => saveStructural("saveStructural")}
                  size="sm"
                  className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                >
                  <FiSave className="me-2" />
                  Хадгалах
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={handleCloseModalStructural}
                  size="sm"
                  className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                >
                  <FiLogOut className="me-2" />
                  Хаах
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showModalNonStructural}
          onHide={handleCloseModalNonStructural}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Байгууллага, аж ахуйн дэргэдэх АБ-ын бүртгэл
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Аймаг, дүүрэг: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataNonStructural.OFFICE_ID}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.OFFICE_ID = e.target.value;
                      if (
                        dropDown.subOffice?.filter(
                          (a) =>
                            parseInt(a.OFFICE_ID) === parseInt(e.target.value)
                        ).length < 0
                      ) {
                        value.SUB_OFFICE_ID = null;
                      }
                      setDataNonStructural({ ...value });
                    }}
                    //options={dropDown.office}
                    options={
                      userData.user.ROLE_LEVEL_ID === 1 ||
                      userData.user.ROLE_LEVEL_ID === 99
                        ? dropDown.office
                        : userData.user.ROLE_LEVEL_ID === 2
                        ? dropDown.office.filter((a) => a.PARENT_ID === 1)
                        : dropDown.office.filter(
                            (a) =>
                              parseInt(a.ID) ===
                              parseInt(userData.user.OFFICE_ID)
                          )
                    }
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Сум, хороо: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataNonStructural.SUB_OFFICE_ID}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.SUB_OFFICE_ID = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                    options={
                      userData.user.ROLE_LEVEL_ID === 5
                        ? dropDown.subOffice
                            ?.filter(
                              (a) =>
                                parseInt(a.OFFICE_ID) ===
                                parseInt(dataNonStructural.OFFICE_ID)
                            )
                            .filter(
                              (a) =>
                                parseInt(a.ID) ===
                                parseInt(userData.user.SUB_OFFICE_ID)
                            )
                        : dropDown.subOffice?.filter(
                            (a) =>
                              parseInt(a.OFFICE_ID) ===
                              parseInt(dataNonStructural.OFFICE_ID)
                          )
                    }
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Ахмадын сантай эсэх: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Select
                    size="sm"
                    value={dataNonStructural.HAS_ELDER_FUND}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.HAS_ELDER_FUND = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  >
                    <option value={999}>Сонгоно уу</option>
                    <option value={1}>Тийм</option>
                    <option value={0}>Үгүй</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Байгууллагын нэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    size="sm"
                    value={dataNonStructural.ORG_NAME}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_NAME = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Үйл ажиллагаа явуулдаг хаяг, байршил:{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    size="sm"
                    value={dataNonStructural.ORG_ADDRESS}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_ADDRESS = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Хариуцлагын хэлбэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Dropdown
                    value={dataNonStructural.ORG_SUB_TYPE_ID}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_SUB_TYPE_ID = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                    options={dropDown.orgSubType}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Даргын нэр: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataNonStructural.HEAD_NAME}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.HEAD_NAME = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Form.Label column sm={2} className="col-form-label-sm">
                  Даргын утас: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataNonStructural.HEAD_PHONE}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.HEAD_PHONE = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm">
                  Бусад ажилтнуудын тоо:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="number"
                    size="sm"
                    value={dataNonStructural.ORG_WORKER}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_WORKER = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>

                <Form.Label column sm={2} className="col-form-label-sm">
                  Холбоо барих утас: <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataNonStructural.ORG_PHONE}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_PHONE = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>

                <Form.Label column sm={2} className="col-form-label-sm">
                  Харьяалалтай ахмадын тоо:{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="input"
                    size="sm"
                    value={dataNonStructural.ELDER_COUNT}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ELDER_COUNT = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
              </Form.Group>
              <h4>Магадлан итгэмжлэл авсан байгууллага</h4>
              <hr />
              <Form.Group className="mb-3 row">
                <Form.Label column sm={2} className="col-form-label-sm mb-3">
                  Авсан огноо:
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type="date"
                    size="sm"
                    value={DateFormat(dataNonStructural.ORG_TRUST_DATE)}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_TRUST_DATE = e.target.value;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_DAY`}
                    label={`Өдрийн үйлчилгээ`}
                    checked={JSON.parse(dataNonStructural.ORG_TRUST_DAY)}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_TRUST_DAY = e.target.checked;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_LESSON`}
                    label={`Сургалт`}
                    checked={JSON.parse(dataNonStructural.ORG_TRUST_LESSON)}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_TRUST_LESSON = e.target.checked;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_HOME`}
                    label={`Гэрийн үйлчилгээ`}
                    checked={JSON.parse(dataNonStructural.ORG_TRUST_HOME)}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_TRUST_HOME = e.target.checked;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Check
                    type="checkbox"
                    id={`ORG_TRUST_CONSULTING`}
                    label={`Ахмад мэргэжилтний зөвлөх үйлчилгээ`}
                    checked={JSON.parse(dataNonStructural.ORG_TRUST_CONSULTING)}
                    onChange={(e) => {
                      let value = dataNonStructural;
                      value.ORG_TRUST_CONSULTING = e.target.checked;
                      setDataNonStructural({ ...value });
                    }}
                  />
                </Col>
                {/* {JSON.stringify(dataNonStructural.ORG_TRUST_DAY)} */}
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => saveStructural("save")}
                  size="sm"
                  className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                >
                  <FiSave className="me-2" />
                  Хадгалах
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={handleCloseModalNonStructural}
                  size="sm"
                  className="me-2 d-flex justify-content-center align-items-center text-white px-2"
                >
                  <FiLogOut className="me-2" />
                  Хаах
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <ConfirmModal
          show={showConfirm}
          close={handleCloseConfirm}
          remove={() => removeStructural()}
        />
      </div>
      {loading && <Loader />}
    </div>
  );
}
