
import axios from "axios";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
export default function AxiosApi({children}) {
    const [cookies, setCookie, removeCookie] = useCookies(['']);

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
    return (
        <>
            { children }
        </>
    )
}