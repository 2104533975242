import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Card from "../components/custom/Card";
import ChartBar from "../components/custom/ChartBar";
import ChartPie from "../components/custom/ChartPie";
import UserContext from "../helper/UserContext";
import { Form } from "react-bootstrap";

export default function Dashboard(props) {
  const userData = useContext(UserContext);
  const [refYear, setRefYear] = useState();
  const [filter, setFilter] = useState({ year: 2 });
  const [card, setCard] = useState({
    ElderCNT: null,
    EventCNT: null,
    FriendlyCNT: null,
    InsideOrgCNT: null,
    InsideTrustCNT: null,
    InsideFundCNT: null,
    OutsideOrgCNT: null,
    OutsideTrustCNT: null,
    OutsideFundCNT: null,
  });
  const [genderPie, setGenderPie] = useState({
    title: "Ахмадын тоо /хүйсээр/",
    label: ["Эрэгтэй", "Эмэгтэй"],
    value: [],
  });
  const genderCNT = [];

  const [ageBar, setAgeBar] = useState({
    title: "Насны ангиллаар",
    label: [
      "55-аас доош",
      "55-59",
      "60-64",
      "65-69",
      "70-79",
      "80-89",
      "90-99",
      "100-ээс дээш",
    ],
    value: [],
  });
  const ageCNT = [];

  const [depPie, setDepPie] = useState({
    title: "Ахмадын тоо /харъяаллаар/",
    label: ["Харъяалалтай", "Харъяалалгүй"],
    value: [],
  });
  const depCNT = [];

  const [elderOfficeBar, setElderOfficeBar] = useState({
    title: "Ахмадын тоо /аймаг, дүүргээр/",
    label: [],
    value: [],
  });
  const elderOfficeLabel = [];
  const elderOfficeCNT = [];

  const [elderSubOfficeBar, setElderSubOfficeBar] = useState({
    title: "Ахмадын тоо /сум, хороогоор/",
    label: [],
    value: [],
  });
  const elderSubOfficeLabel = [];
  const elderSubOfficeCNT = [];

  const [eventOfficeBar, setEventOfficeBar] = useState({
    title: "Үйл ажиллагааны тоо /аймаг, дүүргээр/",
    label: [],
    value: [],
  });
  const eventOfficeLabel = [];
  const eventOfficeCNT = [];

  const [eventSubOfficeBar, setEventSubOfficeBar] = useState({
    title: "Үйл ажиллагааны тоо /сум, хороогоор/",
    label: [],
    value: [],
  });
  const eventSubOfficeLabel = [];
  const eventSubOfficeCNT = [];

  const [eventTypeBar, setEventTypeBar] = useState({
    title: "Үйл ажиллагааны тоо /арга хэмжээний ангиллаар/",
    label: [],
    value: [],
  });
  const eventTypeLabel = [];
  const eventTypeCNT = [];

  const [employmentSubType, setEmploymentSubType] = useState({
    title: "Хөдөлмөр эрхлэлт",
    label: [],
    value: [],
  });

  const employmentSubTypeLabel = [];
  const employmentSubTypeCNT = [];

  const [elderDegree, setElderDegree] = useState({
    title: "Боловсрол",
    label: [],
    value: [],
  });
  const elderDegreeLabel = [];
  const elderDegreeCNT = [];

  const [elderSubDegree, setElderSubDegree] = useState({
    title: "Дээд боловсролын ангиллаар",
    label: [],
    value: [],
  });
  const elderSubDegreeLabel = [];
  const elderSubDegreeCNT = [];

  const [elderFamily, setElderFamily] = useState({
    title: "Ам бүлийн байдал",
    label: [],
    value: [],
  });
  const elderFamilyLabel = [];
  const elderFamilyCNT = [];

  const [elderSubFamily, setElderSubFamily] = useState({
    title: "1 ам бүлийн ангилал",
    label: [],
    value: [],
  });
  const elderSubFamilyLabel = [];
  const elderSubFamilyCNT = [];

  const [elderHomeType, setElderHomeType] = useState({
    title: "Амьжиргаа",
    label: [],
    value: [],
  });

  const elderHomeTypeLabel = [];
  const elderHomeTypeCNT = [];

  const [elderWelfare, setElderWelfare] = useState({
    title: "Тэтгэврийн байдал",
    label: [],
    value: [],
  });

  const elderWelfareLabel = [];
  const elderWelfareCNT = [];

  useEffect(() => {
    fetchFilter();
    //fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchFilter() {
    try {
      const resultYear = (await axios.get("/refYear")).data;
      setRefYear(resultYear);
    } catch (error) {}
  }
  async function fetchData() {
    try {
      const result1 = (
        await axios.post("/dashElder", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setCard((card) => ({ ...card, ElderCNT: result1?.[0].ALL_CNT }));
      genderCNT.push(result1?.[0].MEN_CNT);
      genderCNT.push(result1?.[0].WOMEN_CNT);
      setGenderPie((genderPie) => ({ ...genderPie, value: genderCNT }));
      depCNT.push(result1?.[0].DEP_CNT);
      depCNT.push(result1?.[0].UNDEP_CNT);
      setDepPie((depPie) => ({ ...depPie, value: depCNT }));
      const result2 = (
        await axios.post("/dashElderAge", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      ageCNT.push(result2?.[0].UNDER_55);
      ageCNT.push(result2?.[0].BTWN_55_59);
      ageCNT.push(result2?.[0].BTWN_60_64);
      ageCNT.push(result2?.[0].BTWN_65_69);
      ageCNT.push(result2?.[0].BTWN_70_79);
      ageCNT.push(result2?.[0].BTWN_80_89);
      ageCNT.push(result2?.[0].BTWN_90_99);
      ageCNT.push(result2?.[0].OVER_100);
      setAgeBar((ageBar) => ({ ...ageBar, value: ageCNT }));
      const result3 = (
        await axios.post("/dashElderOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      result3?.map((item, index) => {
        return (
          <>
            {elderOfficeLabel.push(item.ELDER_OFFICE_NAME)}
            {elderOfficeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderOfficeBar((elderOfficeBar) => ({
        ...elderOfficeBar,
        label: elderOfficeLabel,
      }));
      setElderOfficeBar((elderOfficeBar) => ({
        ...elderOfficeBar,
        value: elderOfficeCNT,
      }));
      const result4 = (
        await axios.post("/dashElderSubOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      result4?.map((item, index) => {
        return (
          <>
            {elderSubOfficeLabel.push(item.ELDER_SUB_OFFICE_NAME)}
            {elderSubOfficeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderSubOfficeBar((elderSubOfficeBar) => ({
        ...elderSubOfficeBar,
        label: elderSubOfficeLabel,
      }));
      setElderSubOfficeBar((elderSubOfficeBar) => ({
        ...elderSubOfficeBar,
        value: elderSubOfficeCNT,
      }));
      const result5 = (
        await axios.post("/dashEvent", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      setCard((card) => ({ ...card, EventCNT: result5?.[0].EVENT_CNT }));
      const result6 = (
        await axios.post("/dashEventOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      result6?.map((item, index) => {
        return (
          <>
            {eventOfficeLabel.push(item.OFFICE_NAME)}
            {eventOfficeCNT.push(item.EVENT_CNT)}
          </>
        );
      });
      setEventOfficeBar((elderOfficeBar) => ({
        ...elderOfficeBar,
        label: eventOfficeLabel,
      }));
      setEventOfficeBar((eventOfficeBar) => ({
        ...eventOfficeBar,
        value: eventOfficeCNT,
      }));
      const result7 = (
        await axios.post("/dashEventSubOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      result7?.map((item, index) => {
        return (
          <>
            {eventSubOfficeLabel.push(item.SUB_OFFICE_NAME)}
            {eventSubOfficeCNT.push(item.EVENT_CNT)}
          </>
        );
      });
      setEventSubOfficeBar((elderSubOfficeBar) => ({
        ...elderSubOfficeBar,
        label: eventSubOfficeLabel,
      }));
      setEventSubOfficeBar((eventSubOfficeBar) => ({
        ...eventSubOfficeBar,
        value: eventSubOfficeCNT,
      }));
      const result8 = (
        await axios.post("/dashEventType", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      result8?.map((item, index) => {
        return (
          <>
            {eventTypeLabel.push(item.EVENT_TYPE_NAME)}
            {eventTypeCNT.push(item.EVENT_CNT)}
          </>
        );
      });
      setEventTypeBar((elderTypeBar) => ({
        ...elderTypeBar,
        label: eventTypeLabel,
      }));
      setEventTypeBar((eventTypeBar) => ({
        ...eventTypeBar,
        value: eventTypeCNT,
      }));
      const result9 = (
        await axios.post("/dashEventTypeOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      const result10 = (
        await axios.post("/dashEventTypeSubOffice", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
          FILTER_YEAR: filter.year,
        })
      ).data;
      const result11 = (
        await axios.post("/dashFriendly", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setCard((card) => ({ ...card, FriendlyCNT: result11?.[0].ORG_CNT }));
      const result12 = (
        await axios.post("/dashOrg", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;
      setCard((card) => ({ ...card, InsideOrgCNT: result12?.[0].INSIDE_CNT }));
      setCard((card) => ({
        ...card,
        InsideTrustCNT: result12?.[0].INSIDE_TRUST_COUNT,
      }));
      setCard((card) => ({
        ...card,
        InsideFundCNT: result12?.[0].INSIDE_FUND_COUNT,
      }));
      setCard((card) => ({
        ...card,
        OutsideOrgCNT: result12?.[0].OUTSIDE_CNT,
      }));
      setCard((card) => ({
        ...card,
        OutsideTrustCNT: result12?.[0].OUTSIDE_TRUST_COUNT,
      }));
      setCard((card) => ({
        ...card,
        OutsideFundCNT: result12?.[0].OUTSIDE_FUND_COUNT,
      }));
      const result13 = (
        await axios.post("/dashElderEmploymentSubType", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result13?.map((item, index) => {
        return (
          <>
            {employmentSubTypeLabel.push(item.EMPLOYMENT_SUB_TYPE_NAME)}
            {employmentSubTypeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setEmploymentSubType((employmentSubType) => ({
        ...employmentSubType,
        label: employmentSubTypeLabel,
      }));
      setEmploymentSubType((employmentSubType) => ({
        ...employmentSubType,
        value: employmentSubTypeCNT,
      }));
      const result14 = (
        await axios.post("/dashElderFamily", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result14?.map((item, index) => {
        return (
          <>
            {elderFamilyLabel.push(item.FAMILY_NAME)}
            {elderFamilyCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderFamily((elderFamily) => ({
        ...elderFamily,
        label: elderFamilyLabel,
      }));
      setElderFamily((elderFamily) => ({
        ...elderFamily,
        value: elderFamilyCNT,
      }));
      const result17 = (
        await axios.post("/dashElderSubFamily", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result17?.map((item, index) => {
        return (
          <>
            {elderSubFamilyLabel.push(item.SUB_FAMILY_NAME)}
            {elderSubFamilyCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderSubFamily((elderSubFamily) => ({
        ...elderSubFamily,
        label: elderSubFamilyLabel,
      }));
      setElderSubFamily((elderSubFamily) => ({
        ...elderSubFamily,
        value: elderSubFamilyCNT,
      }));

      const result18 = (
        await axios.post("/dashElderDegree", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result18?.map((item, index) => {
        return (
          <>
            {elderDegreeLabel.push(item.DEGREE_NAME)}
            {elderDegreeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderDegree((elderDegree) => ({
        ...elderDegree,
        label: elderDegreeLabel,
      }));
      setElderDegree((elderDegree) => ({
        ...elderDegree,
        value: elderDegreeCNT,
      }));
      const result19 = (
        await axios.post("/dashElderSubDegree", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result19?.map((item, index) => {
        return (
          <>
            {elderSubDegreeLabel.push(item.SUB_DEGREE_NAME)}
            {elderSubDegreeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderSubDegree((elderSubDegree) => ({
        ...elderSubDegree,
        label: elderSubDegreeLabel,
      }));
      setElderSubDegree((elderSubDegree) => ({
        ...elderSubDegree,
        value: elderSubDegreeCNT,
      }));

      const result15 = (
        await axios.post("/dashElderHomeType", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result15?.map((item, index) => {
        return (
          <>
            {elderHomeTypeLabel.push(item.HOME_TYPE_NAME)}
            {elderHomeTypeCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderHomeType((elderHomeType) => ({
        ...elderHomeType,
        label: elderHomeTypeLabel,
      }));
      setElderHomeType((elderHomeType) => ({
        ...elderHomeType,
        value: elderHomeTypeCNT,
      }));

      const result16 = (
        await axios.post("/dashElderWelfare", {
          USER_OFFICE_ID: userData.user.OFFICE_ID,
          USER_SUB_OFFICE_ID: userData.user.SUB_OFFICE_ID,
          USER_ROLE_LEVEL_ID: userData.user.ROLE_LEVEL_ID,
        })
      ).data;

      result16?.map((item, index) => {
        return (
          <>
            {elderWelfareLabel.push(item.WELFARE_NAME)}
            {elderWelfareCNT.push(item.ELDER_CNT)}
          </>
        );
      });
      setElderWelfare((elderWelfare) => ({
        ...elderWelfare,
        label: elderWelfareLabel,
      }));
      setElderWelfare((elderWelfare) => ({
        ...elderWelfare,
        value: elderWelfareCNT,
      }));
    } catch (error) {}
  }
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <Card
            title="Ахмадын тоо"
            value={card.ElderCNT}
            icon="user"
            className="text-muted"
            textSize="28px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <Card
            title="Нутаг дэвсгэрийн АБ"
            value={card.InsideOrgCNT}
            icon="org"
            textSize="24px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <Card
            title="Байгууллага, аж ахуйн дэргэдэх АБ"
            value={card.OutsideOrgCNT}
            icon="org"
            textSize="24px"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Үйл ажиллагаа"
            value={card.EventCNT}
            icon="event"
            textSize="20px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Ээлтэй байгууллага"
            value={card.FriendlyCNT}
            icon="friendly"
            textSize="20px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Ахмадын сантай байгууллага"
            value={card.InsideFundCNT}
            icon="fund"
            textSize="15px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Магадлан итгэмжлэгдсэн"
            value={card.InsideTrustCNT}
            icon="award"
            textSize="16px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Ахмадын сантай байгууллага"
            value={card.OutsideFundCNT}
            icon="fund"
            textSize="15px"
          />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-2 grid-margin stretch-card">
          <Card
            title="Магадлан итгэмжлэгдсэн"
            value={card.OutsideTrustCNT}
            icon="award"
            textSize="16px"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <Form.Select
                  value={filter.year}
                  onChange={(e) => {
                    let value = filter;
                    value.year = e.target.value;
                    setFilter({ ...value });
                  }}
                  size="sm"
                >
                  {refYear?.map((value, index) => (
                    <option key={value.ID} value={value.ID}>
                      {value.NAME}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <ChartPie pieData={genderPie} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-8 grid-margin stretch-card">
          <ChartBar barData={ageBar} />
        </div>
        {[1, 2, 99].includes(userData.user.ROLE_LEVEL_ID) && (
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
            <ChartBar barData={elderOfficeBar} />
          </div>
        )}
        {[3, 4, 99].includes(userData.user.ROLE_LEVEL_ID) && (
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
            <ChartBar barData={elderSubOfficeBar} />
          </div>
        )}
        {[1, 2, 99].includes(userData.user.ROLE_LEVEL_ID) && (
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
            <ChartBar barData={eventOfficeBar} />
          </div>
        )}
        {[3, 4, 99].includes(userData.user.ROLE_LEVEL_ID) && (
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
            <ChartBar barData={eventSubOfficeBar} />
          </div>
        )}
        <div className="col-12 col-sm-6 col-md-6 col-xl-8 grid-margin stretch-card">
          <ChartBar barData={eventTypeBar} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <ChartPie pieData={depPie} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <ChartPie pieData={employmentSubType} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <ChartPie pieData={elderFamily} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-4 grid-margin stretch-card">
          <ChartPie pieData={elderSubFamily} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
          <ChartPie pieData={elderDegree} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
          <ChartPie pieData={elderSubDegree} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
          <ChartPie pieData={elderHomeType} />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-xl-6 grid-margin stretch-card">
          <ChartPie pieData={elderWelfare} />
        </div>
      </div>
    </>
  );
}
