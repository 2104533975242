import React, { useContext, useState } from "react";
import logo from "../assets/images/logo512.png";
import logoMini from "../assets/images/logo512.png";
import handBook from "../assets/handbook_elder.pdf";
import user from "../assets/images/user.jpg";
import { Button, Col, Dropdown, Form, Modal } from "react-bootstrap";
import UserContext from "../helper/UserContext";
import { FiCheck, FiX } from "react-icons/fi";
import { checkNullString } from "../helper/isVal";
import axios from "axios";
import Loader from "../components/custom/Loader";

export default function Header(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const handleClick = (event) => {
    if (!document.body.classList.contains("sidebar-icon-only")) {
      // 👇️ removing classes from body element
      document.body.classList.add("sidebar-icon-only");
    } else {
      // 👇️ removing classes from body element
      document.body.classList.remove("sidebar-icon-only");
    }
    if (!document.getElementById("sidebar").classList.contains("active")) {
      // 👇️ removing classes from body element
      document.getElementById("sidebar").classList.add("active");
    } else {
      // 👇️ removing classes from body element
      document.getElementById("sidebar").classList.remove("active");
    }
  };

  const [showModalPassword, setShowModalPassword] = useState(false);
  const handleCloseModalPassword = () => setShowModalPassword(false);
  const handleShowModalPassword = () => setShowModalPassword(true);

  const [dataPC, setDataPC] = useState({
    USER_ID: userData.user.USER_ID,
    DELETED_BY: userData.user.USER_ID,
    OLD_PASSWORD: null,
    NEW_PASSWORD: null,
    NEW_CONFIRM_PASSWORD: null,
  });

  function requiredUserPC() {
    if (checkNullString(dataPC.OLD_PASSWORD)) {
      alert("Одоогийн нууц үг оруулна уу");
    } else if (checkNullString(dataPC.NEW_PASSWORD)) {
      alert("Нууц үг оруулна уу");
    } else if (checkNullString(dataPC.NEW_CONFIRM_PASSWORD)) {
      alert("Нууц үг /давтах/ оруулна уу");
    } else if (
      dataPC.NEW_PASSWORD.localeCompare(dataPC.NEW_CONFIRM_PASSWORD) !== 0
    ) {
      alert("Нууц үг /давтах/ буруу байна");
    } else {
      return true;
    }
  }
  const pcData = async () => {
    try {
      if (requiredUserPC()) {
        setLoading(true);
        let result = {};

        let postData = {
          USER_ID: dataPC.USER_ID,
          USER_PASSWORD: dataPC.OLD_PASSWORD,
          USER_NEW_PASSWORD: dataPC.NEW_PASSWORD,
          UPDATED_BY: userData.user.USER_ID,
        };
        result = await axios.post("/regUserPwd", postData);

        alert(result.data.message);
        handleCloseModalPassword();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response.data.error);
    }
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-start navbar-brand-wrapper d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo" href="">
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={logo}
              alt="logo"
              className="w-auto"
              style={{ height: 35 }}
            />
            <p
              className="text-uppercase text-white mb-0 ms-1"
              style={{ lineHeight: 1 }}
            >
              <span className="m-0" style={{ fontSize: 12 }}>
                Монголын
              </span>
              <br />
              <span className="m-0" style={{ fontSize: 8 }}>
                ахмадын холбоо
              </span>
            </p>
          </div>
        </a>
        <a className="navbar-brand brand-logo-mini" href="">
          <img
            src={logoMini}
            alt="logo"
            className="w-auto"
            style={{ height: 35 }}
          />
        </a>
        <button
          className="navbar-toggler align-self-center"
          type="button"
          data-bs-toggle="minimize"
          onClick={() => handleClick()}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <div className="text-uppercase">
          <b>Монголын ахмадын нэгдсэн бүртгэлийн систем</b>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-user-icon">
            <a
              href={handBook}
              target="_blank"
              style={{
                textTransform: "uppercase",
                cursor: "pointer",
                textDecoration: "none",
                color: "#707889",
              }}
            >
              Гарын авлага
            </a>
          </li>
          <li className="nav-item nav-user-icon">
            <Dropdown className="p-0">
              <Dropdown.Toggle variant="light" className="p-0">
                <img src={user} alt="profile" />
                {/* <span className="ps-2">
                  {userData?.user?.USER_LASTNAME.charAt(0) +
                    "." +
                    userData?.user?.USER_FIRSTNAME}
                </span> */}
              </Dropdown.Toggle>

              <Dropdown.Menu variant="light">
                <Dropdown.Item onClick={() => handleShowModalPassword()}>
                  Нууц үг солих
                </Dropdown.Item>
                <Dropdown.Item onClick={props.logout}>Гарах</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-bs-toggle="offcanvas"
          onClick={() => handleClick()}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <Modal
        show={showModalPassword}
        onHide={handleCloseModalPassword}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Хэрэглэгчийн нууц үг солих</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className="col-form-label-sm">
              Одоогийн нууц үг: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                type="password"
                size="sm"
                value={dataPC.OLD_PASSWORD}
                onChange={(e) => {
                  let value = dataPC;
                  value.OLD_PASSWORD = e.target.value;
                  setDataPC({ ...value });
                }}
              />
            </Col>
            <Form.Label className="col-form-label-sm">
              Нууц үг: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                type="password"
                size="sm"
                value={dataPC.NEW_PASSWORD}
                onChange={(e) => {
                  let value = dataPC;
                  value.NEW_PASSWORD = e.target.value;
                  setDataPC({ ...value });
                }}
              />
            </Col>

            <Form.Label className="col-form-label-sm">
              Нууц үг /давтах/: <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <Form.Control
                type="password"
                size="sm"
                value={dataPC.NEW_CONFIRM_PASSWORD}
                onChange={(e) => {
                  let value = dataPC;
                  value.NEW_CONFIRM_PASSWORD = e.target.value;
                  setDataPC({ ...value });
                }}
              />
            </Col>
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="success"
                type="button"
                onClick={() => pcData()}
                size="sm"
                className="me-2 text-white"
              >
                <FiCheck className="me-2" />
                Солих
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={handleCloseModalPassword}
                size="sm"
                className="me-2 text-white"
              >
                <FiX className="me-2" />
                Цуцлах
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {loading && <Loader />}
    </nav>
  );
}
