import React, { Component, useContext } from "react";
import UserContext from "../helper/UserContext";
import Profile from "./Profile";

export default function Sidebar() {
  const userData = useContext(UserContext);
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <Profile />
        <li className="nav-item">
          <a className="nav-link" href="/#/dashboard">
            <i className="mdi mdi-view-dashboard menu-icon"></i>
            <span className="menu-title text-uppercase">Удирдлагын самбар</span>
          </a>
        </li>
        {userData.user.ROLE_LEVEL_ID === 1 ||
        userData.user.ROLE_LEVEL_ID === 2 ? null : (
          <li className="nav-item">
            <a className="nav-link" href="/#/elder">
              <i className="mdi mdi-clipboard-account-outline menu-icon"></i>
              <span className="menu-title text-uppercase">Ахмадын бүртгэл</span>
            </a>
          </li>
        )}
        <li className="nav-item">
          <a className="nav-link" href="/#/event">
            <i className="mdi mdi-checkbox-marked-outline menu-icon"></i>
            <span className="menu-title text-uppercase">Үйл ажиллагаа</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/#/friendlyOrg">
            <i className="mdi mdi-domain menu-icon"></i>
            <span className="menu-title text-uppercase">
              Ээлтэй байгууллага
            </span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/#/org">
            <i className="mdi mdi-office-building menu-icon"></i>
            <span className="menu-title text-uppercase">Байгууллага</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/#/pension">
            <i className="mdi mdi-checkbox-marked-outline menu-icon"></i>
            <span className="menu-title text-uppercase">АТБ Хөтөлбөр</span>
          </a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" href="/#/survey">
            <i className="mdi mdi-feature-search-outline menu-icon"></i>
            <span className="menu-title text-uppercase">Судалгаа</span>
          </a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link" href="/#/report">
            <i className="mdi mdi-file-document menu-icon"></i>
            <span className="menu-title text-uppercase">Тайлан</span>
          </a>
        </li>
        {userData.user.ROLE_LEVEL_ID === 99 ? (
          <li className="nav-item">
            <a className="nav-link" href="/#/settings">
              <i className="mdi mdi-settings menu-icon"></i>
              <span className="menu-title text-uppercase">
                Эрхийн удирдлага
              </span>
            </a>
          </li>
        ) : null}
      </ul>
    </nav>
  );
}
